import { default as C8Vantagepy4MA1JENuData } from "/vercel/path1/components/section/C8Vantage.vue?builder=true";
import { default as anyQuestions55GTWjZsUJData } from "/vercel/path1/components/section/anyQuestions.vue?builder=true";
import { default as bundleoffer2bGGTCeAq3Data } from "/vercel/path1/components/section/bundleoffer.vue?builder=true";
import { default as bundleofferPDFTYLV8nA2nHData } from "/vercel/path1/components/section/bundleofferPDF.vue?builder=true";
import { default as Content_SectionWvwTpIuS60Data } from "/vercel/path1/components/section/cajamarca/Content_Section.vue?builder=true";
import { default as Content_Section_2keGTAIB6DAData } from "/vercel/path1/components/section/cajamarca/Content_Section_2.vue?builder=true";
import { default as Content_Section_3Ip1vsZbHG5Data } from "/vercel/path1/components/section/cajamarca/Content_Section_3.vue?builder=true";
import { default as Content_Section_4875cya3pnCData } from "/vercel/path1/components/section/cajamarca/Content_Section_4.vue?builder=true";
import { default as Content_Section_5WSgBdgcdKKData } from "/vercel/path1/components/section/cajamarca/Content_Section_5.vue?builder=true";
import { default as Content_Section_6oDG65NgqnKData } from "/vercel/path1/components/section/cajamarca/Content_Section_6.vue?builder=true";
import { default as Herop3v3bkxLDxData } from "/vercel/path1/components/section/cajamarca/Hero.vue?builder=true";
import { default as SavingCalculatorQjENU0W6N9Data } from "/vercel/path1/components/section/charts/SavingCalculator.vue?builder=true";
import { default as choosePackage8kz6Prt8dXData } from "/vercel/path1/components/section/choosePackage.vue?builder=true";
import { default as BackgroundTextImagevMD971XuQRData } from "/vercel/path1/components/section/content/BackgroundTextImage.vue?builder=true";
import { default as FeatureInfoIconsImageS6pwJKsM3KData } from "/vercel/path1/components/section/content/FeatureInfoIconsImage.vue?builder=true";
import { default as FeatureWithIconsAndCTARjGoWer8C3Data } from "/vercel/path1/components/section/content/FeatureWithIconsAndCTA.vue?builder=true";
import { default as FeatureWithIconsAndImageKibD4KCBCnData } from "/vercel/path1/components/section/content/FeatureWithIconsAndImage.vue?builder=true";
import { default as FeatureWithImagekKowFBLRyzData } from "/vercel/path1/components/section/content/FeatureWithImage.vue?builder=true";
import { default as FeatureWithImageAndBenefitshhO5A4wF21Data } from "/vercel/path1/components/section/content/FeatureWithImageAndBenefits.vue?builder=true";
import { default as FeatureWithImageAndIconsY9HT1D7lPsData } from "/vercel/path1/components/section/content/FeatureWithImageAndIcons.vue?builder=true";
import { default as InfoWithFullILeftmageLqjHlrrs7AData } from "/vercel/path1/components/section/content/InfoWithFullILeftmage.vue?builder=true";
import { default as InfoWithFullRightImagegTHAMZRwLoData } from "/vercel/path1/components/section/content/InfoWithFullRightImage.vue?builder=true";
import { default as InfoWithLeftImageK1Kwr4PymeData } from "/vercel/path1/components/section/content/InfoWithLeftImage.vue?builder=true";
import { default as InfoWithRightImagehMfgtL3YoLData } from "/vercel/path1/components/section/content/InfoWithRightImage.vue?builder=true";
import { default as creamerLongevityKYiZnzjANVData } from "/vercel/path1/components/section/creamerLongevity.vue?builder=true";
import { default as CTAWithBadgeeWdHYhJ6wMData } from "/vercel/path1/components/section/ctas/CTAWithBadge.vue?builder=true";
import { default as decodingSciencelDQB4lmpMRData } from "/vercel/path1/components/section/decodingScience.vue?builder=true";
import { default as deskSYnTHz5egIData } from "/vercel/path1/components/section/desk.vue?builder=true";
import { default as Content_sectionmo40oXo5FTData } from "/vercel/path1/components/section/drops/Content_section.vue?builder=true";
import { default as Content_section_1091VrH09hGtData } from "/vercel/path1/components/section/drops/Content_section_10.vue?builder=true";
import { default as Content_section_1131kEyZrCqcData } from "/vercel/path1/components/section/drops/Content_section_11.vue?builder=true";
import { default as Content_section_12QGnluQ1TYRData } from "/vercel/path1/components/section/drops/Content_section_12.vue?builder=true";
import { default as Content_section_2bmrwZg8nqPData } from "/vercel/path1/components/section/drops/Content_section_2.vue?builder=true";
import { default as Content_section_3SImoiO1HiLData } from "/vercel/path1/components/section/drops/Content_section_3.vue?builder=true";
import { default as Content_section_4QEibkghJOJData } from "/vercel/path1/components/section/drops/Content_section_4.vue?builder=true";
import { default as Content_section_55o3vtDFymFData } from "/vercel/path1/components/section/drops/Content_section_5.vue?builder=true";
import { default as Content_section_6Z7wLDChpegData } from "/vercel/path1/components/section/drops/Content_section_6.vue?builder=true";
import { default as Content_section_7vyzI7sumEQData } from "/vercel/path1/components/section/drops/Content_section_7.vue?builder=true";
import { default as Content_section_8kbVdC38Q2OData } from "/vercel/path1/components/section/drops/Content_section_8.vue?builder=true";
import { default as Content_section_8_2YFJyvkR6JhData } from "/vercel/path1/components/section/drops/Content_section_8_2.vue?builder=true";
import { default as Content_section_9HMSi43C5EVData } from "/vercel/path1/components/section/drops/Content_section_9.vue?builder=true";
import { default as FooterCejyLO2U4SData } from "/vercel/path1/components/section/drops/Footer.vue?builder=true";
import { default as HeropQfrcNhNPBData } from "/vercel/path1/components/section/drops/Hero.vue?builder=true";
import { default as UpSell1BoWime2f0Data } from "/vercel/path1/components/section/drops/UpSell.vue?builder=true";
import { default as Content_sectionboeDa0jHjlData } from "/vercel/path1/components/section/drops1/Content_section.vue?builder=true";
import { default as Content_section_27qv3fD4AhOData } from "/vercel/path1/components/section/drops1/Content_section_2.vue?builder=true";
import { default as Content_section_3MVx4CWDtVbData } from "/vercel/path1/components/section/drops1/Content_section_3.vue?builder=true";
import { default as Content_section_4V1z2BNSg6KData } from "/vercel/path1/components/section/drops1/Content_section_4.vue?builder=true";
import { default as Hero48bRG6NsfQData } from "/vercel/path1/components/section/drops1/Hero.vue?builder=true";
import { default as CalculatorSavingsProjectionZyuK7ix1o6Data } from "/vercel/path1/components/section/elements/CalculatorSavingsProjection.vue?builder=true";
import { default as equivalent_45uildingitLgXkcjlcData } from "/vercel/path1/components/section/equivalent-uilding.vue?builder=true";
import { default as FAQWithImagebxgsdGGhDOData } from "/vercel/path1/components/section/faqs/FAQWithImage.vue?builder=true";
import { default as Content_SectionIqrpwOxLKGData } from "/vercel/path1/components/section/fitFoam/Content_Section.vue?builder=true";
import { default as statementsBAYZH6tuGsData } from "/vercel/path1/components/section/fitFoam/statements.vue?builder=true";
import { default as swatchesvjQPs5fTXeData } from "/vercel/path1/components/section/fitFoam/swatches.vue?builder=true";
import { default as footer0l6rkhT2ZnData } from "/vercel/path1/components/section/footer.vue?builder=true";
import { default as simpleFooterZ4e9ulWqRpData } from "/vercel/path1/components/section/footer/simpleFooter.vue?builder=true";
import { default as formulatedBodQrJDCLcData } from "/vercel/path1/components/section/formulated.vue?builder=true";
import { default as BgTexImagecfQo4zBKI4Data } from "/vercel/path1/components/section/frostedCookieBuilder/BgTexImage.vue?builder=true";
import { default as CustomersImagessBIbTiiUByData } from "/vercel/path1/components/section/frostedCookieBuilder/CustomersImages.vue?builder=true";
import { default as CustomersReviewsFQgHuZtbTjData } from "/vercel/path1/components/section/frostedCookieBuilder/CustomersReviews.vue?builder=true";
import { default as FooterMsuT5T8bRxData } from "/vercel/path1/components/section/frostedCookieBuilder/Footer.vue?builder=true";
import { default as HeadersvTqN9Agw4Data } from "/vercel/path1/components/section/frostedCookieBuilder/Header.vue?builder=true";
import { default as ImageTextListO0Gg4ucllLData } from "/vercel/path1/components/section/frostedCookieBuilder/ImageTextList.vue?builder=true";
import { default as Logos31rRH0sFpjData } from "/vercel/path1/components/section/frostedCookieBuilder/Logos.vue?builder=true";
import { default as SwatchesType3oOcsAysm1RData } from "/vercel/path1/components/section/frostedCookieBuilder/SwatchesType3.vue?builder=true";
import { default as TextIconsImageFCRebZ8LraData } from "/vercel/path1/components/section/frostedCookieBuilder/TextIconsImage.vue?builder=true";
import { default as TextWhitImagecimeLwd9hsData } from "/vercel/path1/components/section/frostedCookieBuilder/TextWhitImage.vue?builder=true";
import { default as TextWhitImages2Ubi90J9EYtData } from "/vercel/path1/components/section/frostedCookieBuilder/TextWhitImages2.vue?builder=true";
import { default as TextWithImagesJizTW6C1BJData } from "/vercel/path1/components/section/frostedCookieBuilder/TextWithImages.vue?builder=true";
import { default as AmazingCoffeeJxF6jImwsWData } from "/vercel/path1/components/section/gesha/AmazingCoffee.vue?builder=true";
import { default as CoffeeQuaranteebRRw1SDAxWData } from "/vercel/path1/components/section/gesha/CoffeeQuarantee.vue?builder=true";
import { default as FooterrkKsZbFtowData } from "/vercel/path1/components/section/gesha/Footer.vue?builder=true";
import { default as GeshaHeroeqAQYF1xYHData } from "/vercel/path1/components/section/gesha/GeshaHero.vue?builder=true";
import { default as InfoSection0iA8vKLOJjData } from "/vercel/path1/components/section/gesha/InfoSection.vue?builder=true";
import { default as LimitedEditionJ2bYEdCnFTData } from "/vercel/path1/components/section/gesha/LimitedEdition.vue?builder=true";
import { default as MagicHappensCIkh3cJa2PData } from "/vercel/path1/components/section/gesha/MagicHappens.vue?builder=true";
import { default as QueenOfCoffeeV5VXc7m3sPData } from "/vercel/path1/components/section/gesha/QueenOfCoffee.vue?builder=true";
import { default as SoSpecialuWywcXTLT6Data } from "/vercel/path1/components/section/gesha/SoSpecial.vue?builder=true";
import { default as SwatchesWCaSqjgjYiData } from "/vercel/path1/components/section/gesha/Swatches.vue?builder=true";
import { default as healdBenefitsvUDfHRLE78Data } from "/vercel/path1/components/section/healdBenefits.vue?builder=true";
import { default as healthBenefitsCollagenSgffkafQkSData } from "/vercel/path1/components/section/healthBenefitsCollagen.vue?builder=true";
import { default as AllPeoplejZ4QxP0RLxData } from "/vercel/path1/components/section/healthyCoffee/AllPeople.vue?builder=true";
import { default as CommentYCyB0psLzrData } from "/vercel/path1/components/section/healthyCoffee/Comment.vue?builder=true";
import { default as DrinkCoffeeFEksRLZYpUData } from "/vercel/path1/components/section/healthyCoffee/DrinkCoffee.vue?builder=true";
import { default as FootergEy9O6iGwMData } from "/vercel/path1/components/section/healthyCoffee/Footer.vue?builder=true";
import { default as FounderLifeBoost6lhLldnjXwData } from "/vercel/path1/components/section/healthyCoffee/FounderLifeBoost.vue?builder=true";
import { default as FreeShiping8iV7ENmjoHData } from "/vercel/path1/components/section/healthyCoffee/FreeShiping.vue?builder=true";
import { default as FreeShipingImagesDSZIs8THraData } from "/vercel/path1/components/section/healthyCoffee/FreeShipingImages.vue?builder=true";
import { default as FromDeskIiF7LUcfkoData } from "/vercel/path1/components/section/healthyCoffee/FromDesk.vue?builder=true";
import { default as FromMyHousezhntmMvhpSData } from "/vercel/path1/components/section/healthyCoffee/FromMyHouse.vue?builder=true";
import { default as GenerationFdZIep5oqGData } from "/vercel/path1/components/section/healthyCoffee/Generation.vue?builder=true";
import { default as HerozjiNjCDYtgData } from "/vercel/path1/components/section/healthyCoffee/Hero.vue?builder=true";
import { default as LifeBoostIncludesG1gjLo1wArData } from "/vercel/path1/components/section/healthyCoffee/LifeBoostIncludes.vue?builder=true";
import { default as PeopleHaveToSayczIYt69HCUData } from "/vercel/path1/components/section/healthyCoffee/PeopleHaveToSay.vue?builder=true";
import { default as QualitiesFZfIQjVot4Data } from "/vercel/path1/components/section/healthyCoffee/Qualities.vue?builder=true";
import { default as QualitiesMorefOcXNToJ4AData } from "/vercel/path1/components/section/healthyCoffee/QualitiesMore.vue?builder=true";
import { default as ReviewsyeoUl4U1aData } from "/vercel/path1/components/section/healthyCoffee/Review.vue?builder=true";
import { default as Swatches5JSJbtCXa7Data } from "/vercel/path1/components/section/healthyCoffee/Swatches.vue?builder=true";
import { default as AllPeopleyjUWzi8GylData } from "/vercel/path1/components/section/healthyCoffee1/AllPeople.vue?builder=true";
import { default as BannerDiscountbz8NWvAWUiData } from "/vercel/path1/components/section/healthyCoffee1/BannerDiscount.vue?builder=true";
import { default as ButtonTestyx5hX9DvxcData } from "/vercel/path1/components/section/healthyCoffee1/ButtonTest.vue?builder=true";
import { default as CommentZXoMajVFsHData } from "/vercel/path1/components/section/healthyCoffee1/Comment.vue?builder=true";
import { default as DrinkCoffeetiz8M4YIdrData } from "/vercel/path1/components/section/healthyCoffee1/DrinkCoffee.vue?builder=true";
import { default as Footerf5Yv3LJYasData } from "/vercel/path1/components/section/healthyCoffee1/Footer.vue?builder=true";
import { default as FounderLifeBoost2SuU9JXzinData } from "/vercel/path1/components/section/healthyCoffee1/FounderLifeBoost.vue?builder=true";
import { default as FreeShipingFiidEijhgAData } from "/vercel/path1/components/section/healthyCoffee1/FreeShiping.vue?builder=true";
import { default as FreeShipingImagesFfQ7JonHEYData } from "/vercel/path1/components/section/healthyCoffee1/FreeShipingImages.vue?builder=true";
import { default as FromDeskwOxCrS6eaeData } from "/vercel/path1/components/section/healthyCoffee1/FromDesk.vue?builder=true";
import { default as FromMyHouse_452bKU6IAnxxKData } from "/vercel/path1/components/section/healthyCoffee1/FromMyHouse-2.vue?builder=true";
import { default as FromMyHouseeoMR6bATROData } from "/vercel/path1/components/section/healthyCoffee1/FromMyHouse.vue?builder=true";
import { default as Generation_452p323KDjO11Data } from "/vercel/path1/components/section/healthyCoffee1/Generation-2.vue?builder=true";
import { default as GenerationeyLgQXly2HData } from "/vercel/path1/components/section/healthyCoffee1/Generation.vue?builder=true";
import { default as Header5vJRKyuqPKData } from "/vercel/path1/components/section/healthyCoffee1/Header.vue?builder=true";
import { default as Hero6CJpS9gHyRData } from "/vercel/path1/components/section/healthyCoffee1/Hero.vue?builder=true";
import { default as Influencersh6CaB3ldZJData } from "/vercel/path1/components/section/healthyCoffee1/Influencers.vue?builder=true";
import { default as LifeBoostIncludes_4525cTgkPmsxtData } from "/vercel/path1/components/section/healthyCoffee1/LifeBoostIncludes-2.vue?builder=true";
import { default as LifeBoostIncludesADVx2ru5gXData } from "/vercel/path1/components/section/healthyCoffee1/LifeBoostIncludes.vue?builder=true";
import { default as PeopleHaveToSayLRC5cIEQkrData } from "/vercel/path1/components/section/healthyCoffee1/PeopleHaveToSay.vue?builder=true";
import { default as Qualities5UujOBrlwqData } from "/vercel/path1/components/section/healthyCoffee1/Qualities.vue?builder=true";
import { default as QualitiesMoreCnOAzgzSfOData } from "/vercel/path1/components/section/healthyCoffee1/QualitiesMore.vue?builder=true";
import { default as Review_452zFJHwgkDDIData } from "/vercel/path1/components/section/healthyCoffee1/Review-2.vue?builder=true";
import { default as Reviewlh9whXdjGBData } from "/vercel/path1/components/section/healthyCoffee1/Review.vue?builder=true";
import { default as ReviewShort99PlUYAx1hData } from "/vercel/path1/components/section/healthyCoffee1/ReviewShort.vue?builder=true";
import { default as SimpleImageKp5hANKH3uData } from "/vercel/path1/components/section/healthyCoffee1/SimpleImage.vue?builder=true";
import { default as Swatchesv0lBuuR7OdData } from "/vercel/path1/components/section/healthyCoffee1/Swatches.vue?builder=true";
import { default as SwatchesTest_459XLdmBOGwhXData } from "/vercel/path1/components/section/healthyCoffee1/SwatchesTest-9.vue?builder=true";
import { default as SwatchesTest8_455zt9CjEjfasData } from "/vercel/path1/components/section/healthyCoffee1/SwatchesTest8-5.vue?builder=true";
import { default as SwatchesTest8kgrMl1skx2Data } from "/vercel/path1/components/section/healthyCoffee1/SwatchesTest8.vue?builder=true";
import { default as SwatchesVariant_452flEuv7qZPXData } from "/vercel/path1/components/section/healthyCoffee1/SwatchesVariant-2.vue?builder=true";
import { default as SwatchesVariantU4x4E9nuJoData } from "/vercel/path1/components/section/healthyCoffee1/SwatchesVariant.vue?builder=true";
import { default as Testimonials5lWksXNlmSData } from "/vercel/path1/components/section/healthyCoffee1/Testimonials.vue?builder=true";
import { default as TitleWithPointEKbdqm0ZbrData } from "/vercel/path1/components/section/healthyCoffee1/TitleWithPoint.vue?builder=true";
import { default as aboutTextn6gQl1c0BnData } from "/vercel/path1/components/section/healthyCoffee1/aboutText.vue?builder=true";
import { default as AllPeopleiVsIzFkA3nData } from "/vercel/path1/components/section/healtyCoffee22Builder/AllPeople.vue?builder=true";
import { default as AllPeopleImagewB4dzMN96yData } from "/vercel/path1/components/section/healtyCoffee22Builder/AllPeopleImage.vue?builder=true";
import { default as BackgroundImagei30wxtaZq1Data } from "/vercel/path1/components/section/healtyCoffee22Builder/BackgroundImage.vue?builder=true";
import { default as BackgroundImageTextk8hgDvLrdlData } from "/vercel/path1/components/section/healtyCoffee22Builder/BackgroundImageText.vue?builder=true";
import { default as Buttonwfkl08GgZWData } from "/vercel/path1/components/section/healtyCoffee22Builder/Button.vue?builder=true";
import { default as ContentBottomImages5RkLxsqyqQData } from "/vercel/path1/components/section/healtyCoffee22Builder/ContentBottomImages.vue?builder=true";
import { default as ContentImageLeft3CCmCtaCZREData } from "/vercel/path1/components/section/healtyCoffee22Builder/ContentImageLeft3.vue?builder=true";
import { default as ContentImageRight2b40d9EcWPmData } from "/vercel/path1/components/section/healtyCoffee22Builder/ContentImageRight2.vue?builder=true";
import { default as ContentLeftImageihwCEwreVxData } from "/vercel/path1/components/section/healtyCoffee22Builder/ContentLeftImage.vue?builder=true";
import { default as ContentLeftimage2UHy5GOHK0RData } from "/vercel/path1/components/section/healtyCoffee22Builder/ContentLeftimage2.vue?builder=true";
import { default as ContentRightImageWDgdV0mPW4Data } from "/vercel/path1/components/section/healtyCoffee22Builder/ContentRightImage.vue?builder=true";
import { default as ContentSectionWithSvgbGN87id5CEData } from "/vercel/path1/components/section/healtyCoffee22Builder/ContentSectionWithSvg.vue?builder=true";
import { default as ContentTextImageXfVnkMBZlVData } from "/vercel/path1/components/section/healtyCoffee22Builder/ContentTextImage.vue?builder=true";
import { default as FromDesk2L9KChDOx22Data } from "/vercel/path1/components/section/healtyCoffee22Builder/FromDesk2.vue?builder=true";
import { default as ImagesLine2b6BLmmfj3aData } from "/vercel/path1/components/section/healtyCoffee22Builder/ImagesLine2.vue?builder=true";
import { default as LogoSectioneYtSO6W9f0Data } from "/vercel/path1/components/section/healtyCoffee22Builder/LogoSection.vue?builder=true";
import { default as ReferencesX3bLSsjQJ6Data } from "/vercel/path1/components/section/healtyCoffee22Builder/References.vue?builder=true";
import { default as Reviews2PisCHmVGjHData } from "/vercel/path1/components/section/healtyCoffee22Builder/Reviews2.vue?builder=true";
import { default as Reviews3zcWdiKS0E9Data } from "/vercel/path1/components/section/healtyCoffee22Builder/Reviews3.vue?builder=true";
import { default as SectionWithVideoiGAKxIuSXdData } from "/vercel/path1/components/section/healtyCoffee22Builder/SectionWithVideo.vue?builder=true";
import { default as SwatchesType2HGcXTqD386Data } from "/vercel/path1/components/section/healtyCoffee22Builder/SwatchesType2.vue?builder=true";
import { default as benefitso3ftHaLHYjData } from "/vercel/path1/components/section/healtyCoffee22Builder/benefits.vue?builder=true";
import { default as contentrPL6pDmG2vData } from "/vercel/path1/components/section/healtyCoffee22Builder/content.vue?builder=true";
import { default as headerNewXzhg2jA86OData } from "/vercel/path1/components/section/healtyCoffee22Builder/headerNew.vue?builder=true";
import { default as heroF4VprVUxRgData } from "/vercel/path1/components/section/healtyCoffee22Builder/hero.vue?builder=true";
import { default as images_45lineZaWirI2EUvData } from "/vercel/path1/components/section/healtyCoffee22Builder/images-line.vue?builder=true";
import { default as info2QPo2ewebyData } from "/vercel/path1/components/section/healtyCoffee22Builder/info.vue?builder=true";
import { default as hearMels80lNqdrOData } from "/vercel/path1/components/section/hearMe.vue?builder=true";
import { default as HighlightNewsg9W7rK03oCData } from "/vercel/path1/components/section/hero/HighlightNews.vue?builder=true";
import { default as ProductWithShowcase0KAqChGpuvData } from "/vercel/path1/components/section/hero/ProductWithShowcase.vue?builder=true";
import { default as Content_SectionOCLxQcHNyNData } from "/vercel/path1/components/section/indonesia/Content_Section.vue?builder=true";
import { default as Content_Section_127pzBfTZnAData } from "/vercel/path1/components/section/indonesia/Content_Section_1.vue?builder=true";
import { default as Content_Section_10u1g0XmHk8IData } from "/vercel/path1/components/section/indonesia/Content_Section_10.vue?builder=true";
import { default as Content_Section_245tdUkiHkjData } from "/vercel/path1/components/section/indonesia/Content_Section_2.vue?builder=true";
import { default as Content_Section_3hRLypDfO50Data } from "/vercel/path1/components/section/indonesia/Content_Section_3.vue?builder=true";
import { default as Content_Section_4CTc2YOoNp2Data } from "/vercel/path1/components/section/indonesia/Content_Section_4.vue?builder=true";
import { default as Content_Section_57b8Qe40yXiData } from "/vercel/path1/components/section/indonesia/Content_Section_5.vue?builder=true";
import { default as Content_Section_7MEo1K8wYCwData } from "/vercel/path1/components/section/indonesia/Content_Section_7.vue?builder=true";
import { default as Content_Section_8667IU9d25rData } from "/vercel/path1/components/section/indonesia/Content_Section_8.vue?builder=true";
import { default as Content_Section_9WUzZfZQ5P7Data } from "/vercel/path1/components/section/indonesia/Content_Section_9.vue?builder=true";
import { default as Content_TestimonialswArRYqc5TTData } from "/vercel/path1/components/section/indonesia/Content_Testimonials.vue?builder=true";
import { default as FooterViXTtHtgrtData } from "/vercel/path1/components/section/indonesia/Footer.vue?builder=true";
import { default as Hero7icFo2YGfCData } from "/vercel/path1/components/section/indonesia/Hero.vue?builder=true";
import { default as Content_sectiongj11fPut0iData } from "/vercel/path1/components/section/kombucha/Content_section.vue?builder=true";
import { default as Content_section_2ohsZZoaYD8Data } from "/vercel/path1/components/section/kombucha/Content_section_2.vue?builder=true";
import { default as Content_section_3uRXePtBd79Data } from "/vercel/path1/components/section/kombucha/Content_section_3.vue?builder=true";
import { default as Content_section_4bPk2w3UAdkData } from "/vercel/path1/components/section/kombucha/Content_section_4.vue?builder=true";
import { default as Content_section_59ShOEJRSm3Data } from "/vercel/path1/components/section/kombucha/Content_section_5.vue?builder=true";
import { default as Content_section_6KmZOVLqLsvData } from "/vercel/path1/components/section/kombucha/Content_section_6.vue?builder=true";
import { default as Content_section_7jZBBLqaJs1Data } from "/vercel/path1/components/section/kombucha/Content_section_7.vue?builder=true";
import { default as FooterrGk4terkDMData } from "/vercel/path1/components/section/kombucha/Footer.vue?builder=true";
import { default as HerodfPZ168VPFData } from "/vercel/path1/components/section/kombucha/Hero.vue?builder=true";
import { default as lTryptophanvNlrLcgGO9Data } from "/vercel/path1/components/section/lTryptophan.vue?builder=true";
import { default as ContentLefImage38FHg5cBJVaData } from "/vercel/path1/components/section/lifeboostLatteBuilder/ContentLefImage3.vue?builder=true";
import { default as DescriptionImageListeOUhS4yzI4Data } from "/vercel/path1/components/section/lifeboostLatteBuilder/DescriptionImageList.vue?builder=true";
import { default as DescriptionWithListPricingBPziJ17EfJData } from "/vercel/path1/components/section/lifeboostLatteBuilder/DescriptionWithListPricing.vue?builder=true";
import { default as FDABMQos98GddData } from "/vercel/path1/components/section/lifeboostLatteBuilder/FDA.vue?builder=true";
import { default as FooterGJ5XjxzuL3Data } from "/vercel/path1/components/section/lifeboostLatteBuilder/Footer.vue?builder=true";
import { default as HeadingCTAButton2lQfnZSWiYData } from "/vercel/path1/components/section/lifeboostLatteBuilder/HeadingCTAButton.vue?builder=true";
import { default as HerovUuARzhzO1Data } from "/vercel/path1/components/section/lifeboostLatteBuilder/Hero.vue?builder=true";
import { default as HomeStepsjBs4VIdtArData } from "/vercel/path1/components/section/lifeboostLatteBuilder/HomeSteps.vue?builder=true";
import { default as Reviews4iYqoI84aqxData } from "/vercel/path1/components/section/lifeboostLatteBuilder/Reviews4.vue?builder=true";
import { default as TestimonialSwiperCrlIhXXNd2Data } from "/vercel/path1/components/section/lifeboostLatteBuilder/TestimonialSwiper.vue?builder=true";
import { default as TextWithVideoz9krJXHTRZData } from "/vercel/path1/components/section/lifeboostLatteBuilder/TextWithVideo.vue?builder=true";
import { default as metoheroE4yYO039wNData } from "/vercel/path1/components/section/metohero.vue?builder=true";
import { default as mitoCreamerM9fsQ4Zr4XData } from "/vercel/path1/components/section/mitoCreamer.vue?builder=true";
import { default as mitoPrime4ak4pcwfPrData } from "/vercel/path1/components/section/mitoPrime.vue?builder=true";
import { default as BackgroundTitleListImagesvOcPZXGH8YData } from "/vercel/path1/components/section/mushroomBuilder/BackgroundTitleListImages.vue?builder=true";
import { default as BasicSwatchesyP74DPB7qIData } from "/vercel/path1/components/section/mushroomBuilder/BasicSwatches.vue?builder=true";
import { default as CoffeeGuaranteeQBvrlSerlBData } from "/vercel/path1/components/section/mushroomBuilder/CoffeeGuarantee.vue?builder=true";
import { default as ContentWithRoundedIconsqrYO5U678CData } from "/vercel/path1/components/section/mushroomBuilder/ContentWithRoundedIcons.vue?builder=true";
import { default as FrequentlyQuestionsTwoColumnsGEq1oZ5WQCData } from "/vercel/path1/components/section/mushroomBuilder/FrequentlyQuestionsTwoColumns.vue?builder=true";
import { default as Header1mfVJu0asjData } from "/vercel/path1/components/section/mushroomBuilder/Header.vue?builder=true";
import { default as HeroSs9Dfjk74iData } from "/vercel/path1/components/section/mushroomBuilder/Hero.vue?builder=true";
import { default as ImageFeatureListJ4hjcFknT8Data } from "/vercel/path1/components/section/mushroomBuilder/ImageFeatureList.vue?builder=true";
import { default as ReviewWithTitle8Y8GpJZJ2vData } from "/vercel/path1/components/section/mushroomBuilder/ReviewWithTitle.vue?builder=true";
import { default as TableCompareQsGdP0OHZYData } from "/vercel/path1/components/section/mushroomBuilder/TableCompare.vue?builder=true";
import { default as BannerKDj1TiC3eOData } from "/vercel/path1/components/section/mushroomNewBuilder/Banner.vue?builder=true";
import { default as ContentWithLeftImagesdsBpTu4SudData } from "/vercel/path1/components/section/mushroomNewBuilder/ContentWithLeftImages.vue?builder=true";
import { default as DescriptionWithIconsJRfxI8UNeYData } from "/vercel/path1/components/section/mushroomNewBuilder/DescriptionWithIcons.vue?builder=true";
import { default as FooterLsRKegCs0UData } from "/vercel/path1/components/section/mushroomNewBuilder/Footer.vue?builder=true";
import { default as FrequentlyQuestionsiFldlBZwj5Data } from "/vercel/path1/components/section/mushroomNewBuilder/FrequentlyQuestions.vue?builder=true";
import { default as HeadertMfki72uxIData } from "/vercel/path1/components/section/mushroomNewBuilder/Header.vue?builder=true";
import { default as Hero1fp6bxbdo7Data } from "/vercel/path1/components/section/mushroomNewBuilder/Hero.vue?builder=true";
import { default as IconsWithImagesSwiperkVkMTK7RL3Data } from "/vercel/path1/components/section/mushroomNewBuilder/IconsWithImagesSwiper.vue?builder=true";
import { default as ImageReviews47AbS2GVKtData } from "/vercel/path1/components/section/mushroomNewBuilder/ImageReviews.vue?builder=true";
import { default as LogoSwiperE7Ph3cUp92Data } from "/vercel/path1/components/section/mushroomNewBuilder/LogoSwiper.vue?builder=true";
import { default as OptionsProductsWithUpsellB2AQysmgffData } from "/vercel/path1/components/section/mushroomNewBuilder/OptionsProductsWithUpsell.vue?builder=true";
import { default as RaitingStarsBtydeuckuPData } from "/vercel/path1/components/section/mushroomNewBuilder/RaitingStars.vue?builder=true";
import { default as Reviews9Fct2MnNHsData } from "/vercel/path1/components/section/mushroomNewBuilder/Reviews.vue?builder=true";
import { default as SaleBanner9HXPQ72Ik2Data } from "/vercel/path1/components/section/mushroomNewBuilder/SaleBanner.vue?builder=true";
import { default as TableWithCheckbox4oPW1LnTiRData } from "/vercel/path1/components/section/mushroomNewBuilder/TableWithCheckbox.vue?builder=true";
import { default as TestimonialZXlH2jFn6FData } from "/vercel/path1/components/section/mushroomNewBuilder/Testimonial.vue?builder=true";
import { default as TitleDescriptionIcons0YUJz8PF77Data } from "/vercel/path1/components/section/mushroomNewBuilder/TitleDescriptionIcons.vue?builder=true";
import { default as TitleDescriptionsPriceR8psjAYXgvData } from "/vercel/path1/components/section/mushroomNewBuilder/TitleDescriptionsPrice.vue?builder=true";
import { default as TitleDescriptionsPriceDupYq9kUFl1jJData } from "/vercel/path1/components/section/mushroomNewBuilder/TitleDescriptionsPriceDup.vue?builder=true";
import { default as Upsell1lby81iAtPData } from "/vercel/path1/components/section/mushroomNewBuilder/Upsell.vue?builder=true";
import { default as VerifiedzbYzmLk1sqData } from "/vercel/path1/components/section/mushroomNewBuilder/Verified.vue?builder=true";
import { default as TitleDescriptionsPriceICGulvqpCCData } from "/vercel/path1/components/section/mushroomSpecial/TitleDescriptionsPrice.vue?builder=true";
import { default as otherHealthBenefitsjZEwhCV11PData } from "/vercel/path1/components/section/otherHealthBenefits.vue?builder=true";
import { default as Content_sectioniPdBH8DxxkData } from "/vercel/path1/components/section/rainbow/Content_section.vue?builder=true";
import { default as Content_section_10CeLuBnKMDEData } from "/vercel/path1/components/section/rainbow/Content_section_10.vue?builder=true";
import { default as Content_section_11hiuhvUNMfNData } from "/vercel/path1/components/section/rainbow/Content_section_11.vue?builder=true";
import { default as Content_section_12HbtqjvVg0lData } from "/vercel/path1/components/section/rainbow/Content_section_12.vue?builder=true";
import { default as Content_section_2w2fiLWXKXoData } from "/vercel/path1/components/section/rainbow/Content_section_2.vue?builder=true";
import { default as Content_section_3mC21DpGKhxData } from "/vercel/path1/components/section/rainbow/Content_section_3.vue?builder=true";
import { default as Content_section_4M81qScxDBkData } from "/vercel/path1/components/section/rainbow/Content_section_4.vue?builder=true";
import { default as Content_section_5dv0LVxIulYData } from "/vercel/path1/components/section/rainbow/Content_section_5.vue?builder=true";
import { default as Content_section_6F9of4a6RDkData } from "/vercel/path1/components/section/rainbow/Content_section_6.vue?builder=true";
import { default as Content_section_77GUo2XAMo8Data } from "/vercel/path1/components/section/rainbow/Content_section_7.vue?builder=true";
import { default as Content_section_8tEzml4M3asData } from "/vercel/path1/components/section/rainbow/Content_section_8.vue?builder=true";
import { default as Content_section_8_2GXMW8eySvoData } from "/vercel/path1/components/section/rainbow/Content_section_8_2.vue?builder=true";
import { default as Content_section_9YdFmtHKeDqData } from "/vercel/path1/components/section/rainbow/Content_section_9.vue?builder=true";
import { default as FooterDU633cvIO3Data } from "/vercel/path1/components/section/rainbow/Footer.vue?builder=true";
import { default as HeroTUi4WGX4BfData } from "/vercel/path1/components/section/rainbow/Hero.vue?builder=true";
import { default as UpSellxbJpFsyTboData } from "/vercel/path1/components/section/rainbow/UpSell.vue?builder=true";
import { default as referencesJkoIOsNXhFData } from "/vercel/path1/components/section/references.vue?builder=true";
import { default as refundYouJdTGqA2AtLData } from "/vercel/path1/components/section/refundYou.vue?builder=true";
import { default as rhodioPrimePvXyOomNBIData } from "/vercel/path1/components/section/rhodioPrime.vue?builder=true";
import { default as rhodioPrimeOtherBenefitsHaGiqn3yMKData } from "/vercel/path1/components/section/rhodioPrimeOtherBenefits.vue?builder=true";
import { default as rightCollagenCl5ESUf9PrData } from "/vercel/path1/components/section/rightCollagen.vue?builder=true";
import { default as signatureSectionPIN1SKpuGNData } from "/vercel/path1/components/section/signatureSection.vue?builder=true";
import { default as simpleText8kEETsUlg3Data } from "/vercel/path1/components/section/simpleText.vue?builder=true";
import { default as BenefitsrhwZsYLJIiData } from "/vercel/path1/components/section/sips/Benefits.vue?builder=true";
import { default as BoxSeasonRrIRd6aoGVData } from "/vercel/path1/components/section/sips/BoxSeason.vue?builder=true";
import { default as BoxSixCustomCuratedFlavorsQBje91vRBIData } from "/vercel/path1/components/section/sips/BoxSixCustomCuratedFlavors.vue?builder=true";
import { default as FooterpdJtu5CqUlData } from "/vercel/path1/components/section/sips/Footer.vue?builder=true";
import { default as HeroaOcdB0wwbLData } from "/vercel/path1/components/section/sips/Hero.vue?builder=true";
import { default as HowItWorksBHweQcPdl5Data } from "/vercel/path1/components/section/sips/HowItWorks.vue?builder=true";
import { default as QuestionsF67oRaZtEBData } from "/vercel/path1/components/section/sips/Questions.vue?builder=true";
import { default as ReviewseDFfhSyO9LData } from "/vercel/path1/components/section/sips/Reviews.vue?builder=true";
import { default as Schedules4jtb7EWZUData } from "/vercel/path1/components/section/sips/Schedule.vue?builder=true";
import { default as SeasonalSipsClubqsysUXGL8zData } from "/vercel/path1/components/section/sips/SeasonalSipsClub.vue?builder=true";
import { default as SwatchesfuchCBGx4aData } from "/vercel/path1/components/section/sips/Swatches.vue?builder=true";
import { default as TruthAboutCoffee76YnUWclxWData } from "/vercel/path1/components/section/sips/TruthAboutCoffee.vue?builder=true";
import { default as BoxSeasonDYWFa7oyjqData } from "/vercel/path1/components/section/sipsBuilder/BoxSeason.vue?builder=true";
import { default as BoxSixCustomCuratedFlavorsoabeKMSfpkData } from "/vercel/path1/components/section/sipsBuilder/BoxSixCustomCuratedFlavors.vue?builder=true";
import { default as Footer1QgLrzLeRtJData } from "/vercel/path1/components/section/sipsBuilder/Footer1.vue?builder=true";
import { default as HowItWorksIbWbEbZ9iFData } from "/vercel/path1/components/section/sipsBuilder/HowItWorks.vue?builder=true";
import { default as QuestionsBANcF0jaZZData } from "/vercel/path1/components/section/sipsBuilder/Questions.vue?builder=true";
import { default as Reviews4QWVSSHMnKData } from "/vercel/path1/components/section/sipsBuilder/Reviews.vue?builder=true";
import { default as ScheduleUW61cifKQfData } from "/vercel/path1/components/section/sipsBuilder/Schedule.vue?builder=true";
import { default as SeasonalSipsClubX4VFM2qTzyData } from "/vercel/path1/components/section/sipsBuilder/SeasonalSipsClub.vue?builder=true";
import { default as SipsClubHerowsPfoMBUzcData } from "/vercel/path1/components/section/sipsBuilder/SipsClubHero.vue?builder=true";
import { default as SwatchesvNU8yxgbU5Data } from "/vercel/path1/components/section/sipsBuilder/Swatches.vue?builder=true";
import { default as WhyChooseLifeboostEa4UnOg9dOData } from "/vercel/path1/components/section/sipsBuilder/WhyChooseLifeboost.vue?builder=true";
import { default as AskedQuestionsb2TDMFLPVRData } from "/vercel/path1/components/section/snoozeTea/AskedQuestions.vue?builder=true";
import { default as ButtonsInfoONTTUfRfZuData } from "/vercel/path1/components/section/snoozeTea/ButtonsInfo.vue?builder=true";
import { default as ContentButtonsHV05QJKdjOData } from "/vercel/path1/components/section/snoozeTea/ContentButtons.vue?builder=true";
import { default as ContentOpacitydik7OAENfeData } from "/vercel/path1/components/section/snoozeTea/ContentOpacity.vue?builder=true";
import { default as CustomerReviewssXzDaDZOPWData } from "/vercel/path1/components/section/snoozeTea/CustomerReviews.vue?builder=true";
import { default as CustomersVideoVN7ExeeVYUData } from "/vercel/path1/components/section/snoozeTea/CustomersVideo.vue?builder=true";
import { default as FactsgGpHcdOmyBData } from "/vercel/path1/components/section/snoozeTea/Facts.vue?builder=true";
import { default as Footerj7LKPVY7pQData } from "/vercel/path1/components/section/snoozeTea/Footer.vue?builder=true";
import { default as HeroRUG4Gr4bQbData } from "/vercel/path1/components/section/snoozeTea/Hero.vue?builder=true";
import { default as IconsTextuAOSGALoiaData } from "/vercel/path1/components/section/snoozeTea/IconsText.vue?builder=true";
import { default as ItemWAZNKnY4m0Data } from "/vercel/path1/components/section/snoozeTea/Item.vue?builder=true";
import { default as ReferencesdOajAakZeMData } from "/vercel/path1/components/section/snoozeTea/References.vue?builder=true";
import { default as SwatchesrIZzd7nIVLData } from "/vercel/path1/components/section/snoozeTea/Swatches.vue?builder=true";
import { default as Content_sectionPAZer1ZEuwData } from "/vercel/path1/components/section/strawberry/Content_section.vue?builder=true";
import { default as Content_section_2IhoxbibmV7Data } from "/vercel/path1/components/section/strawberry/Content_section_2.vue?builder=true";
import { default as Content_section_3oOi19SJMnFData } from "/vercel/path1/components/section/strawberry/Content_section_3.vue?builder=true";
import { default as Content_section_48gpho1TlLwData } from "/vercel/path1/components/section/strawberry/Content_section_4.vue?builder=true";
import { default as Content_section_5VcNfWj3VJcData } from "/vercel/path1/components/section/strawberry/Content_section_5.vue?builder=true";
import { default as Content_section_6X5WPt7XGYCData } from "/vercel/path1/components/section/strawberry/Content_section_6.vue?builder=true";
import { default as Content_section_7xA1l7yK266Data } from "/vercel/path1/components/section/strawberry/Content_section_7.vue?builder=true";
import { default as Content_videoEaACDaoBIWData } from "/vercel/path1/components/section/strawberry/Content_video.vue?builder=true";
import { default as FooterkorbI5zSZkData } from "/vercel/path1/components/section/strawberry/Footer.vue?builder=true";
import { default as HeroKOzR3x6JIGData } from "/vercel/path1/components/section/strawberry/Hero.vue?builder=true";
import { default as sustainedEnergyS4ZQlPmGxnData } from "/vercel/path1/components/section/sustainedEnergy.vue?builder=true";
import { default as SubscriptionEHSmm3WiPvData } from "/vercel/path1/components/section/swatches/Subscription.vue?builder=true";
import { default as CardGridDgLnEsxiBkData } from "/vercel/path1/components/section/testimonials/CardGrid.vue?builder=true";
import { default as ReviewGridEqXnb8zzJkData } from "/vercel/path1/components/section/testimonials/ReviewGrid.vue?builder=true";
import { default as HeadingWhitTextN5NmelfsXPData } from "/vercel/path1/components/section/textBlocks/HeadingWhitText.vue?builder=true";
import { default as AlertsAnimations4qJpF0J2Q3Data } from "/vercel/path1/components/section/tickers/AlertsAnimations.vue?builder=true";
import { default as tissuesN5CYBItTNFData } from "/vercel/path1/components/section/tissues.vue?builder=true";
import { default as Giftsqpe7dcZMY3Data } from "/vercel/path1/components/section/upsell/Gifts.vue?builder=true";
import { default as HeaderkdYdXGFjsXData } from "/vercel/path1/components/section/upsell/Header.vue?builder=true";
import { default as Herop4dBIRGUWcData } from "/vercel/path1/components/section/upsell/Hero.vue?builder=true";
import { default as MakeEasyUATHPmmX0cData } from "/vercel/path1/components/section/upsell/MakeEasy.vue?builder=true";
import { default as Steps2YyzpHGc26Data } from "/vercel/path1/components/section/upsell/Steps.vue?builder=true";
import { default as Stickingo0qleVvyUTData } from "/vercel/path1/components/section/upsell/Sticking.vue?builder=true";
import { default as VipProgramNixOvcrlhrData } from "/vercel/path1/components/section/upsell/VipProgram.vue?builder=true";
import { default as footerFUhg8sN8qqData } from "/vercel/path1/components/section/upsell/footer.vue?builder=true";
import { default as BackgroundImageWithCheckListqJsB19YFMmData } from "/vercel/path1/components/section/zipifyBuilder/BackgroundImageWithCheckList.vue?builder=true";
import { default as BgTexImageqJHaSN10cEData } from "/vercel/path1/components/section/zipifyBuilder/BgTexImage.vue?builder=true";
import { default as ContentWithLeftImagej78doqz5zlData } from "/vercel/path1/components/section/zipifyBuilder/ContentWithLeftImage.vue?builder=true";
import { default as DescriptionLeftRightImage4J1OaS0jmZData } from "/vercel/path1/components/section/zipifyBuilder/DescriptionLeftRightImage.vue?builder=true";
import { default as DescriptionListWithImageUX6yGZQkjqData } from "/vercel/path1/components/section/zipifyBuilder/DescriptionListWithImage.vue?builder=true";
import { default as HeaderNdtKjFDpIWData } from "/vercel/path1/components/section/zipifyBuilder/Header.vue?builder=true";
import { default as HeroBackgroundAndImageJPpzJw5kriData } from "/vercel/path1/components/section/zipifyBuilder/HeroBackgroundAndImage.vue?builder=true";
import { default as HeroWithBackgroundbXGYwRGFQrData } from "/vercel/path1/components/section/zipifyBuilder/HeroWithBackground.vue?builder=true";
import { default as ImageWhitQuoteLIFeoSjBAvData } from "/vercel/path1/components/section/zipifyBuilder/ImageWhitQuote.vue?builder=true";
import { default as ImageWithIconsListhb4tRt6idGData } from "/vercel/path1/components/section/zipifyBuilder/ImageWithIconsList.vue?builder=true";
import { default as LongTextWithImages9PfixeJBkzData } from "/vercel/path1/components/section/zipifyBuilder/LongTextWithImages.vue?builder=true";
import { default as ProductHighlightsAndRecognitionoo436RlSHfData } from "/vercel/path1/components/section/zipifyBuilder/ProductHighlightsAndRecognition.vue?builder=true";
import { default as SwatchesMb2EJZ2p8rData } from "/vercel/path1/components/section/zipifyBuilder/Swatches.vue?builder=true";
import { default as TestimonialsWithOptionsColorsk3fDsm8M7CData } from "/vercel/path1/components/section/zipifyBuilder/TestimonialsWithOptionsColors.vue?builder=true";
import { default as TitleWithImageCTA5HyF8pPzVXData } from "/vercel/path1/components/section/zipifyBuilder/TitleWithImageCTA.vue?builder=true";

export default [
  {
    name: "C8Vantage",
    data: C8Vantagepy4MA1JENuData,
    component: () => import("/vercel/path1/components/section/C8Vantage.vue")
  },
  {
    name: "anyQuestions",
    data: anyQuestions55GTWjZsUJData,
    component: () => import("/vercel/path1/components/section/anyQuestions.vue")
  },
  {
    name: "bundleoffer",
    data: bundleoffer2bGGTCeAq3Data,
    component: () => import("/vercel/path1/components/section/bundleoffer.vue")
  },
  {
    name: "bundleofferPDF",
    data: bundleofferPDFTYLV8nA2nHData,
    component: () => import("/vercel/path1/components/section/bundleofferPDF.vue")
  },
  {
    name: "Content_Section",
    data: Content_SectionWvwTpIuS60Data,
    component: () => import("/vercel/path1/components/section/cajamarca/Content_Section.vue")
  },
  {
    name: "Content_Section_2",
    data: Content_Section_2keGTAIB6DAData,
    component: () => import("/vercel/path1/components/section/cajamarca/Content_Section_2.vue")
  },
  {
    name: "Content_Section_3",
    data: Content_Section_3Ip1vsZbHG5Data,
    component: () => import("/vercel/path1/components/section/cajamarca/Content_Section_3.vue")
  },
  {
    name: "Content_Section_4",
    data: Content_Section_4875cya3pnCData,
    component: () => import("/vercel/path1/components/section/cajamarca/Content_Section_4.vue")
  },
  {
    name: "Content_Section_5",
    data: Content_Section_5WSgBdgcdKKData,
    component: () => import("/vercel/path1/components/section/cajamarca/Content_Section_5.vue")
  },
  {
    name: "Content_Section_6",
    data: Content_Section_6oDG65NgqnKData,
    component: () => import("/vercel/path1/components/section/cajamarca/Content_Section_6.vue")
  },
  {
    name: "Hero",
    data: Herop3v3bkxLDxData,
    component: () => import("/vercel/path1/components/section/cajamarca/Hero.vue")
  },
  {
    name: "SavingCalculator",
    data: SavingCalculatorQjENU0W6N9Data,
    component: () => import("/vercel/path1/components/section/charts/SavingCalculator.vue")
  },
  {
    name: "choosePackage",
    data: choosePackage8kz6Prt8dXData,
    component: () => import("/vercel/path1/components/section/choosePackage.vue")
  },
  {
    name: "BackgroundTextImage",
    data: BackgroundTextImagevMD971XuQRData,
    component: () => import("/vercel/path1/components/section/content/BackgroundTextImage.vue")
  },
  {
    name: "FeatureInfoIconsImage",
    data: FeatureInfoIconsImageS6pwJKsM3KData,
    component: () => import("/vercel/path1/components/section/content/FeatureInfoIconsImage.vue")
  },
  {
    name: "FeatureWithIconsAndCTA",
    data: FeatureWithIconsAndCTARjGoWer8C3Data,
    component: () => import("/vercel/path1/components/section/content/FeatureWithIconsAndCTA.vue")
  },
  {
    name: "FeatureWithIconsAndImage",
    data: FeatureWithIconsAndImageKibD4KCBCnData,
    component: () => import("/vercel/path1/components/section/content/FeatureWithIconsAndImage.vue")
  },
  {
    name: "FeatureWithImage",
    data: FeatureWithImagekKowFBLRyzData,
    component: () => import("/vercel/path1/components/section/content/FeatureWithImage.vue")
  },
  {
    name: "FeatureWithImageAndBenefits",
    data: FeatureWithImageAndBenefitshhO5A4wF21Data,
    component: () => import("/vercel/path1/components/section/content/FeatureWithImageAndBenefits.vue")
  },
  {
    name: "FeatureWithImageAndIcons",
    data: FeatureWithImageAndIconsY9HT1D7lPsData,
    component: () => import("/vercel/path1/components/section/content/FeatureWithImageAndIcons.vue")
  },
  {
    name: "InfoWithFullILeftmage",
    data: InfoWithFullILeftmageLqjHlrrs7AData,
    component: () => import("/vercel/path1/components/section/content/InfoWithFullILeftmage.vue")
  },
  {
    name: "InfoWithFullRightImage",
    data: InfoWithFullRightImagegTHAMZRwLoData,
    component: () => import("/vercel/path1/components/section/content/InfoWithFullRightImage.vue")
  },
  {
    name: "InfoWithLeftImage",
    data: InfoWithLeftImageK1Kwr4PymeData,
    component: () => import("/vercel/path1/components/section/content/InfoWithLeftImage.vue")
  },
  {
    name: "InfoWithRightImage",
    data: InfoWithRightImagehMfgtL3YoLData,
    component: () => import("/vercel/path1/components/section/content/InfoWithRightImage.vue")
  },
  {
    name: "creamerLongevity",
    data: creamerLongevityKYiZnzjANVData,
    component: () => import("/vercel/path1/components/section/creamerLongevity.vue")
  },
  {
    name: "CTAWithBadge",
    data: CTAWithBadgeeWdHYhJ6wMData,
    component: () => import("/vercel/path1/components/section/ctas/CTAWithBadge.vue")
  },
  {
    name: "decodingScience",
    data: decodingSciencelDQB4lmpMRData,
    component: () => import("/vercel/path1/components/section/decodingScience.vue")
  },
  {
    name: "desk",
    data: deskSYnTHz5egIData,
    component: () => import("/vercel/path1/components/section/desk.vue")
  },
  {
    name: "Content_section",
    data: Content_sectionmo40oXo5FTData,
    component: () => import("/vercel/path1/components/section/drops/Content_section.vue")
  },
  {
    name: "Content_section_10",
    data: Content_section_1091VrH09hGtData,
    component: () => import("/vercel/path1/components/section/drops/Content_section_10.vue")
  },
  {
    name: "Content_section_11",
    data: Content_section_1131kEyZrCqcData,
    component: () => import("/vercel/path1/components/section/drops/Content_section_11.vue")
  },
  {
    name: "Content_section_12",
    data: Content_section_12QGnluQ1TYRData,
    component: () => import("/vercel/path1/components/section/drops/Content_section_12.vue")
  },
  {
    name: "Content_section_2",
    data: Content_section_2bmrwZg8nqPData,
    component: () => import("/vercel/path1/components/section/drops/Content_section_2.vue")
  },
  {
    name: "Content_section_3",
    data: Content_section_3SImoiO1HiLData,
    component: () => import("/vercel/path1/components/section/drops/Content_section_3.vue")
  },
  {
    name: "Content_section_4",
    data: Content_section_4QEibkghJOJData,
    component: () => import("/vercel/path1/components/section/drops/Content_section_4.vue")
  },
  {
    name: "Content_section_5",
    data: Content_section_55o3vtDFymFData,
    component: () => import("/vercel/path1/components/section/drops/Content_section_5.vue")
  },
  {
    name: "Content_section_6",
    data: Content_section_6Z7wLDChpegData,
    component: () => import("/vercel/path1/components/section/drops/Content_section_6.vue")
  },
  {
    name: "Content_section_7",
    data: Content_section_7vyzI7sumEQData,
    component: () => import("/vercel/path1/components/section/drops/Content_section_7.vue")
  },
  {
    name: "Content_section_8",
    data: Content_section_8kbVdC38Q2OData,
    component: () => import("/vercel/path1/components/section/drops/Content_section_8.vue")
  },
  {
    name: "Content_section_8_2",
    data: Content_section_8_2YFJyvkR6JhData,
    component: () => import("/vercel/path1/components/section/drops/Content_section_8_2.vue")
  },
  {
    name: "Content_section_9",
    data: Content_section_9HMSi43C5EVData,
    component: () => import("/vercel/path1/components/section/drops/Content_section_9.vue")
  },
  {
    name: "Footer",
    data: FooterCejyLO2U4SData,
    component: () => import("/vercel/path1/components/section/drops/Footer.vue")
  },
  {
    name: "Hero",
    data: HeropQfrcNhNPBData,
    component: () => import("/vercel/path1/components/section/drops/Hero.vue")
  },
  {
    name: "UpSell",
    data: UpSell1BoWime2f0Data,
    component: () => import("/vercel/path1/components/section/drops/UpSell.vue")
  },
  {
    name: "Content_section",
    data: Content_sectionboeDa0jHjlData,
    component: () => import("/vercel/path1/components/section/drops1/Content_section.vue")
  },
  {
    name: "Content_section_2",
    data: Content_section_27qv3fD4AhOData,
    component: () => import("/vercel/path1/components/section/drops1/Content_section_2.vue")
  },
  {
    name: "Content_section_3",
    data: Content_section_3MVx4CWDtVbData,
    component: () => import("/vercel/path1/components/section/drops1/Content_section_3.vue")
  },
  {
    name: "Content_section_4",
    data: Content_section_4V1z2BNSg6KData,
    component: () => import("/vercel/path1/components/section/drops1/Content_section_4.vue")
  },
  {
    name: "Hero",
    data: Hero48bRG6NsfQData,
    component: () => import("/vercel/path1/components/section/drops1/Hero.vue")
  },
  {
    name: "CalculatorSavingsProjection",
    data: CalculatorSavingsProjectionZyuK7ix1o6Data,
    component: () => import("/vercel/path1/components/section/elements/CalculatorSavingsProjection.vue")
  },
  {
    name: "equivalent-uilding",
    data: equivalent_45uildingitLgXkcjlcData,
    component: () => import("/vercel/path1/components/section/equivalent-uilding.vue")
  },
  {
    name: "FAQWithImage",
    data: FAQWithImagebxgsdGGhDOData,
    component: () => import("/vercel/path1/components/section/faqs/FAQWithImage.vue")
  },
  {
    name: "Content_Section",
    data: Content_SectionIqrpwOxLKGData,
    component: () => import("/vercel/path1/components/section/fitFoam/Content_Section.vue")
  },
  {
    name: "statements",
    data: statementsBAYZH6tuGsData,
    component: () => import("/vercel/path1/components/section/fitFoam/statements.vue")
  },
  {
    name: "swatches",
    data: swatchesvjQPs5fTXeData,
    component: () => import("/vercel/path1/components/section/fitFoam/swatches.vue")
  },
  {
    name: "footer",
    data: footer0l6rkhT2ZnData,
    component: () => import("/vercel/path1/components/section/footer.vue")
  },
  {
    name: "simpleFooter",
    data: simpleFooterZ4e9ulWqRpData,
    component: () => import("/vercel/path1/components/section/footer/simpleFooter.vue")
  },
  {
    name: "formulated",
    data: formulatedBodQrJDCLcData,
    component: () => import("/vercel/path1/components/section/formulated.vue")
  },
  {
    name: "BgTexImage",
    data: BgTexImagecfQo4zBKI4Data,
    component: () => import("/vercel/path1/components/section/frostedCookieBuilder/BgTexImage.vue")
  },
  {
    name: "CustomersImages",
    data: CustomersImagessBIbTiiUByData,
    component: () => import("/vercel/path1/components/section/frostedCookieBuilder/CustomersImages.vue")
  },
  {
    name: "CustomersReviews",
    data: CustomersReviewsFQgHuZtbTjData,
    component: () => import("/vercel/path1/components/section/frostedCookieBuilder/CustomersReviews.vue")
  },
  {
    name: "Footer",
    data: FooterMsuT5T8bRxData,
    component: () => import("/vercel/path1/components/section/frostedCookieBuilder/Footer.vue")
  },
  {
    name: "Header",
    data: HeadersvTqN9Agw4Data,
    component: () => import("/vercel/path1/components/section/frostedCookieBuilder/Header.vue")
  },
  {
    name: "ImageTextList",
    data: ImageTextListO0Gg4ucllLData,
    component: () => import("/vercel/path1/components/section/frostedCookieBuilder/ImageTextList.vue")
  },
  {
    name: "Logos",
    data: Logos31rRH0sFpjData,
    component: () => import("/vercel/path1/components/section/frostedCookieBuilder/Logos.vue")
  },
  {
    name: "SwatchesType3",
    data: SwatchesType3oOcsAysm1RData,
    component: () => import("/vercel/path1/components/section/frostedCookieBuilder/SwatchesType3.vue")
  },
  {
    name: "TextIconsImage",
    data: TextIconsImageFCRebZ8LraData,
    component: () => import("/vercel/path1/components/section/frostedCookieBuilder/TextIconsImage.vue")
  },
  {
    name: "TextWhitImage",
    data: TextWhitImagecimeLwd9hsData,
    component: () => import("/vercel/path1/components/section/frostedCookieBuilder/TextWhitImage.vue")
  },
  {
    name: "TextWhitImages2",
    data: TextWhitImages2Ubi90J9EYtData,
    component: () => import("/vercel/path1/components/section/frostedCookieBuilder/TextWhitImages2.vue")
  },
  {
    name: "TextWithImages",
    data: TextWithImagesJizTW6C1BJData,
    component: () => import("/vercel/path1/components/section/frostedCookieBuilder/TextWithImages.vue")
  },
  {
    name: "AmazingCoffee",
    data: AmazingCoffeeJxF6jImwsWData,
    component: () => import("/vercel/path1/components/section/gesha/AmazingCoffee.vue")
  },
  {
    name: "CoffeeQuarantee",
    data: CoffeeQuaranteebRRw1SDAxWData,
    component: () => import("/vercel/path1/components/section/gesha/CoffeeQuarantee.vue")
  },
  {
    name: "Footer",
    data: FooterrkKsZbFtowData,
    component: () => import("/vercel/path1/components/section/gesha/Footer.vue")
  },
  {
    name: "GeshaHero",
    data: GeshaHeroeqAQYF1xYHData,
    component: () => import("/vercel/path1/components/section/gesha/GeshaHero.vue")
  },
  {
    name: "InfoSection",
    data: InfoSection0iA8vKLOJjData,
    component: () => import("/vercel/path1/components/section/gesha/InfoSection.vue")
  },
  {
    name: "LimitedEdition",
    data: LimitedEditionJ2bYEdCnFTData,
    component: () => import("/vercel/path1/components/section/gesha/LimitedEdition.vue")
  },
  {
    name: "MagicHappens",
    data: MagicHappensCIkh3cJa2PData,
    component: () => import("/vercel/path1/components/section/gesha/MagicHappens.vue")
  },
  {
    name: "QueenOfCoffee",
    data: QueenOfCoffeeV5VXc7m3sPData,
    component: () => import("/vercel/path1/components/section/gesha/QueenOfCoffee.vue")
  },
  {
    name: "SoSpecial",
    data: SoSpecialuWywcXTLT6Data,
    component: () => import("/vercel/path1/components/section/gesha/SoSpecial.vue")
  },
  {
    name: "Swatches",
    data: SwatchesWCaSqjgjYiData,
    component: () => import("/vercel/path1/components/section/gesha/Swatches.vue")
  },
  {
    name: "healdBenefits",
    data: healdBenefitsvUDfHRLE78Data,
    component: () => import("/vercel/path1/components/section/healdBenefits.vue")
  },
  {
    name: "healthBenefitsCollagen",
    data: healthBenefitsCollagenSgffkafQkSData,
    component: () => import("/vercel/path1/components/section/healthBenefitsCollagen.vue")
  },
  {
    name: "AllPeople",
    data: AllPeoplejZ4QxP0RLxData,
    component: () => import("/vercel/path1/components/section/healthyCoffee/AllPeople.vue")
  },
  {
    name: "Comment",
    data: CommentYCyB0psLzrData,
    component: () => import("/vercel/path1/components/section/healthyCoffee/Comment.vue")
  },
  {
    name: "DrinkCoffee",
    data: DrinkCoffeeFEksRLZYpUData,
    component: () => import("/vercel/path1/components/section/healthyCoffee/DrinkCoffee.vue")
  },
  {
    name: "Footer",
    data: FootergEy9O6iGwMData,
    component: () => import("/vercel/path1/components/section/healthyCoffee/Footer.vue")
  },
  {
    name: "FounderLifeBoost",
    data: FounderLifeBoost6lhLldnjXwData,
    component: () => import("/vercel/path1/components/section/healthyCoffee/FounderLifeBoost.vue")
  },
  {
    name: "FreeShiping",
    data: FreeShiping8iV7ENmjoHData,
    component: () => import("/vercel/path1/components/section/healthyCoffee/FreeShiping.vue")
  },
  {
    name: "FreeShipingImages",
    data: FreeShipingImagesDSZIs8THraData,
    component: () => import("/vercel/path1/components/section/healthyCoffee/FreeShipingImages.vue")
  },
  {
    name: "FromDesk",
    data: FromDeskIiF7LUcfkoData,
    component: () => import("/vercel/path1/components/section/healthyCoffee/FromDesk.vue")
  },
  {
    name: "FromMyHouse",
    data: FromMyHousezhntmMvhpSData,
    component: () => import("/vercel/path1/components/section/healthyCoffee/FromMyHouse.vue")
  },
  {
    name: "Generation",
    data: GenerationFdZIep5oqGData,
    component: () => import("/vercel/path1/components/section/healthyCoffee/Generation.vue")
  },
  {
    name: "Hero",
    data: HerozjiNjCDYtgData,
    component: () => import("/vercel/path1/components/section/healthyCoffee/Hero.vue")
  },
  {
    name: "LifeBoostIncludes",
    data: LifeBoostIncludesG1gjLo1wArData,
    component: () => import("/vercel/path1/components/section/healthyCoffee/LifeBoostIncludes.vue")
  },
  {
    name: "PeopleHaveToSay",
    data: PeopleHaveToSayczIYt69HCUData,
    component: () => import("/vercel/path1/components/section/healthyCoffee/PeopleHaveToSay.vue")
  },
  {
    name: "Qualities",
    data: QualitiesFZfIQjVot4Data,
    component: () => import("/vercel/path1/components/section/healthyCoffee/Qualities.vue")
  },
  {
    name: "QualitiesMore",
    data: QualitiesMorefOcXNToJ4AData,
    component: () => import("/vercel/path1/components/section/healthyCoffee/QualitiesMore.vue")
  },
  {
    name: "Review",
    data: ReviewsyeoUl4U1aData,
    component: () => import("/vercel/path1/components/section/healthyCoffee/Review.vue")
  },
  {
    name: "Swatches",
    data: Swatches5JSJbtCXa7Data,
    component: () => import("/vercel/path1/components/section/healthyCoffee/Swatches.vue")
  },
  {
    name: "AllPeople",
    data: AllPeopleyjUWzi8GylData,
    component: () => import("/vercel/path1/components/section/healthyCoffee1/AllPeople.vue")
  },
  {
    name: "BannerDiscount",
    data: BannerDiscountbz8NWvAWUiData,
    component: () => import("/vercel/path1/components/section/healthyCoffee1/BannerDiscount.vue")
  },
  {
    name: "ButtonTest",
    data: ButtonTestyx5hX9DvxcData,
    component: () => import("/vercel/path1/components/section/healthyCoffee1/ButtonTest.vue")
  },
  {
    name: "Comment",
    data: CommentZXoMajVFsHData,
    component: () => import("/vercel/path1/components/section/healthyCoffee1/Comment.vue")
  },
  {
    name: "DrinkCoffee",
    data: DrinkCoffeetiz8M4YIdrData,
    component: () => import("/vercel/path1/components/section/healthyCoffee1/DrinkCoffee.vue")
  },
  {
    name: "Footer",
    data: Footerf5Yv3LJYasData,
    component: () => import("/vercel/path1/components/section/healthyCoffee1/Footer.vue")
  },
  {
    name: "FounderLifeBoost",
    data: FounderLifeBoost2SuU9JXzinData,
    component: () => import("/vercel/path1/components/section/healthyCoffee1/FounderLifeBoost.vue")
  },
  {
    name: "FreeShiping",
    data: FreeShipingFiidEijhgAData,
    component: () => import("/vercel/path1/components/section/healthyCoffee1/FreeShiping.vue")
  },
  {
    name: "FreeShipingImages",
    data: FreeShipingImagesFfQ7JonHEYData,
    component: () => import("/vercel/path1/components/section/healthyCoffee1/FreeShipingImages.vue")
  },
  {
    name: "FromDesk",
    data: FromDeskwOxCrS6eaeData,
    component: () => import("/vercel/path1/components/section/healthyCoffee1/FromDesk.vue")
  },
  {
    name: "FromMyHouse-2",
    data: FromMyHouse_452bKU6IAnxxKData,
    component: () => import("/vercel/path1/components/section/healthyCoffee1/FromMyHouse-2.vue")
  },
  {
    name: "FromMyHouse",
    data: FromMyHouseeoMR6bATROData,
    component: () => import("/vercel/path1/components/section/healthyCoffee1/FromMyHouse.vue")
  },
  {
    name: "Generation-2",
    data: Generation_452p323KDjO11Data,
    component: () => import("/vercel/path1/components/section/healthyCoffee1/Generation-2.vue")
  },
  {
    name: "Generation",
    data: GenerationeyLgQXly2HData,
    component: () => import("/vercel/path1/components/section/healthyCoffee1/Generation.vue")
  },
  {
    name: "Header",
    data: Header5vJRKyuqPKData,
    component: () => import("/vercel/path1/components/section/healthyCoffee1/Header.vue")
  },
  {
    name: "Hero",
    data: Hero6CJpS9gHyRData,
    component: () => import("/vercel/path1/components/section/healthyCoffee1/Hero.vue")
  },
  {
    name: "Influencers",
    data: Influencersh6CaB3ldZJData,
    component: () => import("/vercel/path1/components/section/healthyCoffee1/Influencers.vue")
  },
  {
    name: "LifeBoostIncludes-2",
    data: LifeBoostIncludes_4525cTgkPmsxtData,
    component: () => import("/vercel/path1/components/section/healthyCoffee1/LifeBoostIncludes-2.vue")
  },
  {
    name: "LifeBoostIncludes",
    data: LifeBoostIncludesADVx2ru5gXData,
    component: () => import("/vercel/path1/components/section/healthyCoffee1/LifeBoostIncludes.vue")
  },
  {
    name: "PeopleHaveToSay",
    data: PeopleHaveToSayLRC5cIEQkrData,
    component: () => import("/vercel/path1/components/section/healthyCoffee1/PeopleHaveToSay.vue")
  },
  {
    name: "Qualities",
    data: Qualities5UujOBrlwqData,
    component: () => import("/vercel/path1/components/section/healthyCoffee1/Qualities.vue")
  },
  {
    name: "QualitiesMore",
    data: QualitiesMoreCnOAzgzSfOData,
    component: () => import("/vercel/path1/components/section/healthyCoffee1/QualitiesMore.vue")
  },
  {
    name: "Review-2",
    data: Review_452zFJHwgkDDIData,
    component: () => import("/vercel/path1/components/section/healthyCoffee1/Review-2.vue")
  },
  {
    name: "Review",
    data: Reviewlh9whXdjGBData,
    component: () => import("/vercel/path1/components/section/healthyCoffee1/Review.vue")
  },
  {
    name: "ReviewShort",
    data: ReviewShort99PlUYAx1hData,
    component: () => import("/vercel/path1/components/section/healthyCoffee1/ReviewShort.vue")
  },
  {
    name: "SimpleImage",
    data: SimpleImageKp5hANKH3uData,
    component: () => import("/vercel/path1/components/section/healthyCoffee1/SimpleImage.vue")
  },
  {
    name: "Swatches",
    data: Swatchesv0lBuuR7OdData,
    component: () => import("/vercel/path1/components/section/healthyCoffee1/Swatches.vue")
  },
  {
    name: "SwatchesTest-9",
    data: SwatchesTest_459XLdmBOGwhXData,
    component: () => import("/vercel/path1/components/section/healthyCoffee1/SwatchesTest-9.vue")
  },
  {
    name: "SwatchesTest8-5",
    data: SwatchesTest8_455zt9CjEjfasData,
    component: () => import("/vercel/path1/components/section/healthyCoffee1/SwatchesTest8-5.vue")
  },
  {
    name: "SwatchesTest8",
    data: SwatchesTest8kgrMl1skx2Data,
    component: () => import("/vercel/path1/components/section/healthyCoffee1/SwatchesTest8.vue")
  },
  {
    name: "SwatchesVariant-2",
    data: SwatchesVariant_452flEuv7qZPXData,
    component: () => import("/vercel/path1/components/section/healthyCoffee1/SwatchesVariant-2.vue")
  },
  {
    name: "SwatchesVariant",
    data: SwatchesVariantU4x4E9nuJoData,
    component: () => import("/vercel/path1/components/section/healthyCoffee1/SwatchesVariant.vue")
  },
  {
    name: "Testimonials",
    data: Testimonials5lWksXNlmSData,
    component: () => import("/vercel/path1/components/section/healthyCoffee1/Testimonials.vue")
  },
  {
    name: "TitleWithPoint",
    data: TitleWithPointEKbdqm0ZbrData,
    component: () => import("/vercel/path1/components/section/healthyCoffee1/TitleWithPoint.vue")
  },
  {
    name: "aboutText",
    data: aboutTextn6gQl1c0BnData,
    component: () => import("/vercel/path1/components/section/healthyCoffee1/aboutText.vue")
  },
  {
    name: "AllPeople",
    data: AllPeopleiVsIzFkA3nData,
    component: () => import("/vercel/path1/components/section/healtyCoffee22Builder/AllPeople.vue")
  },
  {
    name: "AllPeopleImage",
    data: AllPeopleImagewB4dzMN96yData,
    component: () => import("/vercel/path1/components/section/healtyCoffee22Builder/AllPeopleImage.vue")
  },
  {
    name: "BackgroundImage",
    data: BackgroundImagei30wxtaZq1Data,
    component: () => import("/vercel/path1/components/section/healtyCoffee22Builder/BackgroundImage.vue")
  },
  {
    name: "BackgroundImageText",
    data: BackgroundImageTextk8hgDvLrdlData,
    component: () => import("/vercel/path1/components/section/healtyCoffee22Builder/BackgroundImageText.vue")
  },
  {
    name: "Button",
    data: Buttonwfkl08GgZWData,
    component: () => import("/vercel/path1/components/section/healtyCoffee22Builder/Button.vue")
  },
  {
    name: "ContentBottomImages",
    data: ContentBottomImages5RkLxsqyqQData,
    component: () => import("/vercel/path1/components/section/healtyCoffee22Builder/ContentBottomImages.vue")
  },
  {
    name: "ContentImageLeft3",
    data: ContentImageLeft3CCmCtaCZREData,
    component: () => import("/vercel/path1/components/section/healtyCoffee22Builder/ContentImageLeft3.vue")
  },
  {
    name: "ContentImageRight2",
    data: ContentImageRight2b40d9EcWPmData,
    component: () => import("/vercel/path1/components/section/healtyCoffee22Builder/ContentImageRight2.vue")
  },
  {
    name: "ContentLeftImage",
    data: ContentLeftImageihwCEwreVxData,
    component: () => import("/vercel/path1/components/section/healtyCoffee22Builder/ContentLeftImage.vue")
  },
  {
    name: "ContentLeftimage2",
    data: ContentLeftimage2UHy5GOHK0RData,
    component: () => import("/vercel/path1/components/section/healtyCoffee22Builder/ContentLeftimage2.vue")
  },
  {
    name: "ContentRightImage",
    data: ContentRightImageWDgdV0mPW4Data,
    component: () => import("/vercel/path1/components/section/healtyCoffee22Builder/ContentRightImage.vue")
  },
  {
    name: "ContentSectionWithSvg",
    data: ContentSectionWithSvgbGN87id5CEData,
    component: () => import("/vercel/path1/components/section/healtyCoffee22Builder/ContentSectionWithSvg.vue")
  },
  {
    name: "ContentTextImage",
    data: ContentTextImageXfVnkMBZlVData,
    component: () => import("/vercel/path1/components/section/healtyCoffee22Builder/ContentTextImage.vue")
  },
  {
    name: "FromDesk2",
    data: FromDesk2L9KChDOx22Data,
    component: () => import("/vercel/path1/components/section/healtyCoffee22Builder/FromDesk2.vue")
  },
  {
    name: "ImagesLine2",
    data: ImagesLine2b6BLmmfj3aData,
    component: () => import("/vercel/path1/components/section/healtyCoffee22Builder/ImagesLine2.vue")
  },
  {
    name: "LogoSection",
    data: LogoSectioneYtSO6W9f0Data,
    component: () => import("/vercel/path1/components/section/healtyCoffee22Builder/LogoSection.vue")
  },
  {
    name: "References",
    data: ReferencesX3bLSsjQJ6Data,
    component: () => import("/vercel/path1/components/section/healtyCoffee22Builder/References.vue")
  },
  {
    name: "Reviews2",
    data: Reviews2PisCHmVGjHData,
    component: () => import("/vercel/path1/components/section/healtyCoffee22Builder/Reviews2.vue")
  },
  {
    name: "Reviews3",
    data: Reviews3zcWdiKS0E9Data,
    component: () => import("/vercel/path1/components/section/healtyCoffee22Builder/Reviews3.vue")
  },
  {
    name: "SectionWithVideo",
    data: SectionWithVideoiGAKxIuSXdData,
    component: () => import("/vercel/path1/components/section/healtyCoffee22Builder/SectionWithVideo.vue")
  },
  {
    name: "SwatchesType2",
    data: SwatchesType2HGcXTqD386Data,
    component: () => import("/vercel/path1/components/section/healtyCoffee22Builder/SwatchesType2.vue")
  },
  {
    name: "benefits",
    data: benefitso3ftHaLHYjData,
    component: () => import("/vercel/path1/components/section/healtyCoffee22Builder/benefits.vue")
  },
  {
    name: "content",
    data: contentrPL6pDmG2vData,
    component: () => import("/vercel/path1/components/section/healtyCoffee22Builder/content.vue")
  },
  {
    name: "headerNew",
    data: headerNewXzhg2jA86OData,
    component: () => import("/vercel/path1/components/section/healtyCoffee22Builder/headerNew.vue")
  },
  {
    name: "hero",
    data: heroF4VprVUxRgData,
    component: () => import("/vercel/path1/components/section/healtyCoffee22Builder/hero.vue")
  },
  {
    name: "images-line",
    data: images_45lineZaWirI2EUvData,
    component: () => import("/vercel/path1/components/section/healtyCoffee22Builder/images-line.vue")
  },
  {
    name: "info",
    data: info2QPo2ewebyData,
    component: () => import("/vercel/path1/components/section/healtyCoffee22Builder/info.vue")
  },
  {
    name: "hearMe",
    data: hearMels80lNqdrOData,
    component: () => import("/vercel/path1/components/section/hearMe.vue")
  },
  {
    name: "HighlightNews",
    data: HighlightNewsg9W7rK03oCData,
    component: () => import("/vercel/path1/components/section/hero/HighlightNews.vue")
  },
  {
    name: "ProductWithShowcase",
    data: ProductWithShowcase0KAqChGpuvData,
    component: () => import("/vercel/path1/components/section/hero/ProductWithShowcase.vue")
  },
  {
    name: "Content_Section",
    data: Content_SectionOCLxQcHNyNData,
    component: () => import("/vercel/path1/components/section/indonesia/Content_Section.vue")
  },
  {
    name: "Content_Section_1",
    data: Content_Section_127pzBfTZnAData,
    component: () => import("/vercel/path1/components/section/indonesia/Content_Section_1.vue")
  },
  {
    name: "Content_Section_10",
    data: Content_Section_10u1g0XmHk8IData,
    component: () => import("/vercel/path1/components/section/indonesia/Content_Section_10.vue")
  },
  {
    name: "Content_Section_2",
    data: Content_Section_245tdUkiHkjData,
    component: () => import("/vercel/path1/components/section/indonesia/Content_Section_2.vue")
  },
  {
    name: "Content_Section_3",
    data: Content_Section_3hRLypDfO50Data,
    component: () => import("/vercel/path1/components/section/indonesia/Content_Section_3.vue")
  },
  {
    name: "Content_Section_4",
    data: Content_Section_4CTc2YOoNp2Data,
    component: () => import("/vercel/path1/components/section/indonesia/Content_Section_4.vue")
  },
  {
    name: "Content_Section_5",
    data: Content_Section_57b8Qe40yXiData,
    component: () => import("/vercel/path1/components/section/indonesia/Content_Section_5.vue")
  },
  {
    name: "Content_Section_7",
    data: Content_Section_7MEo1K8wYCwData,
    component: () => import("/vercel/path1/components/section/indonesia/Content_Section_7.vue")
  },
  {
    name: "Content_Section_8",
    data: Content_Section_8667IU9d25rData,
    component: () => import("/vercel/path1/components/section/indonesia/Content_Section_8.vue")
  },
  {
    name: "Content_Section_9",
    data: Content_Section_9WUzZfZQ5P7Data,
    component: () => import("/vercel/path1/components/section/indonesia/Content_Section_9.vue")
  },
  {
    name: "Content_Testimonials",
    data: Content_TestimonialswArRYqc5TTData,
    component: () => import("/vercel/path1/components/section/indonesia/Content_Testimonials.vue")
  },
  {
    name: "Footer",
    data: FooterViXTtHtgrtData,
    component: () => import("/vercel/path1/components/section/indonesia/Footer.vue")
  },
  {
    name: "Hero",
    data: Hero7icFo2YGfCData,
    component: () => import("/vercel/path1/components/section/indonesia/Hero.vue")
  },
  {
    name: "Content_section",
    data: Content_sectiongj11fPut0iData,
    component: () => import("/vercel/path1/components/section/kombucha/Content_section.vue")
  },
  {
    name: "Content_section_2",
    data: Content_section_2ohsZZoaYD8Data,
    component: () => import("/vercel/path1/components/section/kombucha/Content_section_2.vue")
  },
  {
    name: "Content_section_3",
    data: Content_section_3uRXePtBd79Data,
    component: () => import("/vercel/path1/components/section/kombucha/Content_section_3.vue")
  },
  {
    name: "Content_section_4",
    data: Content_section_4bPk2w3UAdkData,
    component: () => import("/vercel/path1/components/section/kombucha/Content_section_4.vue")
  },
  {
    name: "Content_section_5",
    data: Content_section_59ShOEJRSm3Data,
    component: () => import("/vercel/path1/components/section/kombucha/Content_section_5.vue")
  },
  {
    name: "Content_section_6",
    data: Content_section_6KmZOVLqLsvData,
    component: () => import("/vercel/path1/components/section/kombucha/Content_section_6.vue")
  },
  {
    name: "Content_section_7",
    data: Content_section_7jZBBLqaJs1Data,
    component: () => import("/vercel/path1/components/section/kombucha/Content_section_7.vue")
  },
  {
    name: "Footer",
    data: FooterrGk4terkDMData,
    component: () => import("/vercel/path1/components/section/kombucha/Footer.vue")
  },
  {
    name: "Hero",
    data: HerodfPZ168VPFData,
    component: () => import("/vercel/path1/components/section/kombucha/Hero.vue")
  },
  {
    name: "lTryptophan",
    data: lTryptophanvNlrLcgGO9Data,
    component: () => import("/vercel/path1/components/section/lTryptophan.vue")
  },
  {
    name: "ContentLefImage3",
    data: ContentLefImage38FHg5cBJVaData,
    component: () => import("/vercel/path1/components/section/lifeboostLatteBuilder/ContentLefImage3.vue")
  },
  {
    name: "DescriptionImageList",
    data: DescriptionImageListeOUhS4yzI4Data,
    component: () => import("/vercel/path1/components/section/lifeboostLatteBuilder/DescriptionImageList.vue")
  },
  {
    name: "DescriptionWithListPricing",
    data: DescriptionWithListPricingBPziJ17EfJData,
    component: () => import("/vercel/path1/components/section/lifeboostLatteBuilder/DescriptionWithListPricing.vue")
  },
  {
    name: "FDA",
    data: FDABMQos98GddData,
    component: () => import("/vercel/path1/components/section/lifeboostLatteBuilder/FDA.vue")
  },
  {
    name: "Footer",
    data: FooterGJ5XjxzuL3Data,
    component: () => import("/vercel/path1/components/section/lifeboostLatteBuilder/Footer.vue")
  },
  {
    name: "HeadingCTAButton",
    data: HeadingCTAButton2lQfnZSWiYData,
    component: () => import("/vercel/path1/components/section/lifeboostLatteBuilder/HeadingCTAButton.vue")
  },
  {
    name: "Hero",
    data: HerovUuARzhzO1Data,
    component: () => import("/vercel/path1/components/section/lifeboostLatteBuilder/Hero.vue")
  },
  {
    name: "HomeSteps",
    data: HomeStepsjBs4VIdtArData,
    component: () => import("/vercel/path1/components/section/lifeboostLatteBuilder/HomeSteps.vue")
  },
  {
    name: "Reviews4",
    data: Reviews4iYqoI84aqxData,
    component: () => import("/vercel/path1/components/section/lifeboostLatteBuilder/Reviews4.vue")
  },
  {
    name: "TestimonialSwiper",
    data: TestimonialSwiperCrlIhXXNd2Data,
    component: () => import("/vercel/path1/components/section/lifeboostLatteBuilder/TestimonialSwiper.vue")
  },
  {
    name: "TextWithVideo",
    data: TextWithVideoz9krJXHTRZData,
    component: () => import("/vercel/path1/components/section/lifeboostLatteBuilder/TextWithVideo.vue")
  },
  {
    name: "metohero",
    data: metoheroE4yYO039wNData,
    component: () => import("/vercel/path1/components/section/metohero.vue")
  },
  {
    name: "mitoCreamer",
    data: mitoCreamerM9fsQ4Zr4XData,
    component: () => import("/vercel/path1/components/section/mitoCreamer.vue")
  },
  {
    name: "mitoPrime",
    data: mitoPrime4ak4pcwfPrData,
    component: () => import("/vercel/path1/components/section/mitoPrime.vue")
  },
  {
    name: "BackgroundTitleListImages",
    data: BackgroundTitleListImagesvOcPZXGH8YData,
    component: () => import("/vercel/path1/components/section/mushroomBuilder/BackgroundTitleListImages.vue")
  },
  {
    name: "BasicSwatches",
    data: BasicSwatchesyP74DPB7qIData,
    component: () => import("/vercel/path1/components/section/mushroomBuilder/BasicSwatches.vue")
  },
  {
    name: "CoffeeGuarantee",
    data: CoffeeGuaranteeQBvrlSerlBData,
    component: () => import("/vercel/path1/components/section/mushroomBuilder/CoffeeGuarantee.vue")
  },
  {
    name: "ContentWithRoundedIcons",
    data: ContentWithRoundedIconsqrYO5U678CData,
    component: () => import("/vercel/path1/components/section/mushroomBuilder/ContentWithRoundedIcons.vue")
  },
  {
    name: "FrequentlyQuestionsTwoColumns",
    data: FrequentlyQuestionsTwoColumnsGEq1oZ5WQCData,
    component: () => import("/vercel/path1/components/section/mushroomBuilder/FrequentlyQuestionsTwoColumns.vue")
  },
  {
    name: "Header",
    data: Header1mfVJu0asjData,
    component: () => import("/vercel/path1/components/section/mushroomBuilder/Header.vue")
  },
  {
    name: "Hero",
    data: HeroSs9Dfjk74iData,
    component: () => import("/vercel/path1/components/section/mushroomBuilder/Hero.vue")
  },
  {
    name: "ImageFeatureList",
    data: ImageFeatureListJ4hjcFknT8Data,
    component: () => import("/vercel/path1/components/section/mushroomBuilder/ImageFeatureList.vue")
  },
  {
    name: "ReviewWithTitle",
    data: ReviewWithTitle8Y8GpJZJ2vData,
    component: () => import("/vercel/path1/components/section/mushroomBuilder/ReviewWithTitle.vue")
  },
  {
    name: "TableCompare",
    data: TableCompareQsGdP0OHZYData,
    component: () => import("/vercel/path1/components/section/mushroomBuilder/TableCompare.vue")
  },
  {
    name: "Banner",
    data: BannerKDj1TiC3eOData,
    component: () => import("/vercel/path1/components/section/mushroomNewBuilder/Banner.vue")
  },
  {
    name: "ContentWithLeftImages",
    data: ContentWithLeftImagesdsBpTu4SudData,
    component: () => import("/vercel/path1/components/section/mushroomNewBuilder/ContentWithLeftImages.vue")
  },
  {
    name: "DescriptionWithIcons",
    data: DescriptionWithIconsJRfxI8UNeYData,
    component: () => import("/vercel/path1/components/section/mushroomNewBuilder/DescriptionWithIcons.vue")
  },
  {
    name: "Footer",
    data: FooterLsRKegCs0UData,
    component: () => import("/vercel/path1/components/section/mushroomNewBuilder/Footer.vue")
  },
  {
    name: "FrequentlyQuestions",
    data: FrequentlyQuestionsiFldlBZwj5Data,
    component: () => import("/vercel/path1/components/section/mushroomNewBuilder/FrequentlyQuestions.vue")
  },
  {
    name: "Header",
    data: HeadertMfki72uxIData,
    component: () => import("/vercel/path1/components/section/mushroomNewBuilder/Header.vue")
  },
  {
    name: "Hero",
    data: Hero1fp6bxbdo7Data,
    component: () => import("/vercel/path1/components/section/mushroomNewBuilder/Hero.vue")
  },
  {
    name: "IconsWithImagesSwiper",
    data: IconsWithImagesSwiperkVkMTK7RL3Data,
    component: () => import("/vercel/path1/components/section/mushroomNewBuilder/IconsWithImagesSwiper.vue")
  },
  {
    name: "ImageReviews",
    data: ImageReviews47AbS2GVKtData,
    component: () => import("/vercel/path1/components/section/mushroomNewBuilder/ImageReviews.vue")
  },
  {
    name: "LogoSwiper",
    data: LogoSwiperE7Ph3cUp92Data,
    component: () => import("/vercel/path1/components/section/mushroomNewBuilder/LogoSwiper.vue")
  },
  {
    name: "OptionsProductsWithUpsell",
    data: OptionsProductsWithUpsellB2AQysmgffData,
    component: () => import("/vercel/path1/components/section/mushroomNewBuilder/OptionsProductsWithUpsell.vue")
  },
  {
    name: "RaitingStars",
    data: RaitingStarsBtydeuckuPData,
    component: () => import("/vercel/path1/components/section/mushroomNewBuilder/RaitingStars.vue")
  },
  {
    name: "Reviews",
    data: Reviews9Fct2MnNHsData,
    component: () => import("/vercel/path1/components/section/mushroomNewBuilder/Reviews.vue")
  },
  {
    name: "SaleBanner",
    data: SaleBanner9HXPQ72Ik2Data,
    component: () => import("/vercel/path1/components/section/mushroomNewBuilder/SaleBanner.vue")
  },
  {
    name: "TableWithCheckbox",
    data: TableWithCheckbox4oPW1LnTiRData,
    component: () => import("/vercel/path1/components/section/mushroomNewBuilder/TableWithCheckbox.vue")
  },
  {
    name: "Testimonial",
    data: TestimonialZXlH2jFn6FData,
    component: () => import("/vercel/path1/components/section/mushroomNewBuilder/Testimonial.vue")
  },
  {
    name: "TitleDescriptionIcons",
    data: TitleDescriptionIcons0YUJz8PF77Data,
    component: () => import("/vercel/path1/components/section/mushroomNewBuilder/TitleDescriptionIcons.vue")
  },
  {
    name: "TitleDescriptionsPrice",
    data: TitleDescriptionsPriceR8psjAYXgvData,
    component: () => import("/vercel/path1/components/section/mushroomNewBuilder/TitleDescriptionsPrice.vue")
  },
  {
    name: "TitleDescriptionsPriceDup",
    data: TitleDescriptionsPriceDupYq9kUFl1jJData,
    component: () => import("/vercel/path1/components/section/mushroomNewBuilder/TitleDescriptionsPriceDup.vue")
  },
  {
    name: "Upsell",
    data: Upsell1lby81iAtPData,
    component: () => import("/vercel/path1/components/section/mushroomNewBuilder/Upsell.vue")
  },
  {
    name: "Verified",
    data: VerifiedzbYzmLk1sqData,
    component: () => import("/vercel/path1/components/section/mushroomNewBuilder/Verified.vue")
  },
  {
    name: "TitleDescriptionsPrice",
    data: TitleDescriptionsPriceICGulvqpCCData,
    component: () => import("/vercel/path1/components/section/mushroomSpecial/TitleDescriptionsPrice.vue")
  },
  {
    name: "otherHealthBenefits",
    data: otherHealthBenefitsjZEwhCV11PData,
    component: () => import("/vercel/path1/components/section/otherHealthBenefits.vue")
  },
  {
    name: "Content_section",
    data: Content_sectioniPdBH8DxxkData,
    component: () => import("/vercel/path1/components/section/rainbow/Content_section.vue")
  },
  {
    name: "Content_section_10",
    data: Content_section_10CeLuBnKMDEData,
    component: () => import("/vercel/path1/components/section/rainbow/Content_section_10.vue")
  },
  {
    name: "Content_section_11",
    data: Content_section_11hiuhvUNMfNData,
    component: () => import("/vercel/path1/components/section/rainbow/Content_section_11.vue")
  },
  {
    name: "Content_section_12",
    data: Content_section_12HbtqjvVg0lData,
    component: () => import("/vercel/path1/components/section/rainbow/Content_section_12.vue")
  },
  {
    name: "Content_section_2",
    data: Content_section_2w2fiLWXKXoData,
    component: () => import("/vercel/path1/components/section/rainbow/Content_section_2.vue")
  },
  {
    name: "Content_section_3",
    data: Content_section_3mC21DpGKhxData,
    component: () => import("/vercel/path1/components/section/rainbow/Content_section_3.vue")
  },
  {
    name: "Content_section_4",
    data: Content_section_4M81qScxDBkData,
    component: () => import("/vercel/path1/components/section/rainbow/Content_section_4.vue")
  },
  {
    name: "Content_section_5",
    data: Content_section_5dv0LVxIulYData,
    component: () => import("/vercel/path1/components/section/rainbow/Content_section_5.vue")
  },
  {
    name: "Content_section_6",
    data: Content_section_6F9of4a6RDkData,
    component: () => import("/vercel/path1/components/section/rainbow/Content_section_6.vue")
  },
  {
    name: "Content_section_7",
    data: Content_section_77GUo2XAMo8Data,
    component: () => import("/vercel/path1/components/section/rainbow/Content_section_7.vue")
  },
  {
    name: "Content_section_8",
    data: Content_section_8tEzml4M3asData,
    component: () => import("/vercel/path1/components/section/rainbow/Content_section_8.vue")
  },
  {
    name: "Content_section_8_2",
    data: Content_section_8_2GXMW8eySvoData,
    component: () => import("/vercel/path1/components/section/rainbow/Content_section_8_2.vue")
  },
  {
    name: "Content_section_9",
    data: Content_section_9YdFmtHKeDqData,
    component: () => import("/vercel/path1/components/section/rainbow/Content_section_9.vue")
  },
  {
    name: "Footer",
    data: FooterDU633cvIO3Data,
    component: () => import("/vercel/path1/components/section/rainbow/Footer.vue")
  },
  {
    name: "Hero",
    data: HeroTUi4WGX4BfData,
    component: () => import("/vercel/path1/components/section/rainbow/Hero.vue")
  },
  {
    name: "UpSell",
    data: UpSellxbJpFsyTboData,
    component: () => import("/vercel/path1/components/section/rainbow/UpSell.vue")
  },
  {
    name: "references",
    data: referencesJkoIOsNXhFData,
    component: () => import("/vercel/path1/components/section/references.vue")
  },
  {
    name: "refundYou",
    data: refundYouJdTGqA2AtLData,
    component: () => import("/vercel/path1/components/section/refundYou.vue")
  },
  {
    name: "rhodioPrime",
    data: rhodioPrimePvXyOomNBIData,
    component: () => import("/vercel/path1/components/section/rhodioPrime.vue")
  },
  {
    name: "rhodioPrimeOtherBenefits",
    data: rhodioPrimeOtherBenefitsHaGiqn3yMKData,
    component: () => import("/vercel/path1/components/section/rhodioPrimeOtherBenefits.vue")
  },
  {
    name: "rightCollagen",
    data: rightCollagenCl5ESUf9PrData,
    component: () => import("/vercel/path1/components/section/rightCollagen.vue")
  },
  {
    name: "signatureSection",
    data: signatureSectionPIN1SKpuGNData,
    component: () => import("/vercel/path1/components/section/signatureSection.vue")
  },
  {
    name: "simpleText",
    data: simpleText8kEETsUlg3Data,
    component: () => import("/vercel/path1/components/section/simpleText.vue")
  },
  {
    name: "Benefits",
    data: BenefitsrhwZsYLJIiData,
    component: () => import("/vercel/path1/components/section/sips/Benefits.vue")
  },
  {
    name: "BoxSeason",
    data: BoxSeasonRrIRd6aoGVData,
    component: () => import("/vercel/path1/components/section/sips/BoxSeason.vue")
  },
  {
    name: "BoxSixCustomCuratedFlavors",
    data: BoxSixCustomCuratedFlavorsQBje91vRBIData,
    component: () => import("/vercel/path1/components/section/sips/BoxSixCustomCuratedFlavors.vue")
  },
  {
    name: "Footer",
    data: FooterpdJtu5CqUlData,
    component: () => import("/vercel/path1/components/section/sips/Footer.vue")
  },
  {
    name: "Hero",
    data: HeroaOcdB0wwbLData,
    component: () => import("/vercel/path1/components/section/sips/Hero.vue")
  },
  {
    name: "HowItWorks",
    data: HowItWorksBHweQcPdl5Data,
    component: () => import("/vercel/path1/components/section/sips/HowItWorks.vue")
  },
  {
    name: "Questions",
    data: QuestionsF67oRaZtEBData,
    component: () => import("/vercel/path1/components/section/sips/Questions.vue")
  },
  {
    name: "Reviews",
    data: ReviewseDFfhSyO9LData,
    component: () => import("/vercel/path1/components/section/sips/Reviews.vue")
  },
  {
    name: "Schedule",
    data: Schedules4jtb7EWZUData,
    component: () => import("/vercel/path1/components/section/sips/Schedule.vue")
  },
  {
    name: "SeasonalSipsClub",
    data: SeasonalSipsClubqsysUXGL8zData,
    component: () => import("/vercel/path1/components/section/sips/SeasonalSipsClub.vue")
  },
  {
    name: "Swatches",
    data: SwatchesfuchCBGx4aData,
    component: () => import("/vercel/path1/components/section/sips/Swatches.vue")
  },
  {
    name: "TruthAboutCoffee",
    data: TruthAboutCoffee76YnUWclxWData,
    component: () => import("/vercel/path1/components/section/sips/TruthAboutCoffee.vue")
  },
  {
    name: "BoxSeason",
    data: BoxSeasonDYWFa7oyjqData,
    component: () => import("/vercel/path1/components/section/sipsBuilder/BoxSeason.vue")
  },
  {
    name: "BoxSixCustomCuratedFlavors",
    data: BoxSixCustomCuratedFlavorsoabeKMSfpkData,
    component: () => import("/vercel/path1/components/section/sipsBuilder/BoxSixCustomCuratedFlavors.vue")
  },
  {
    name: "Footer1",
    data: Footer1QgLrzLeRtJData,
    component: () => import("/vercel/path1/components/section/sipsBuilder/Footer1.vue")
  },
  {
    name: "HowItWorks",
    data: HowItWorksIbWbEbZ9iFData,
    component: () => import("/vercel/path1/components/section/sipsBuilder/HowItWorks.vue")
  },
  {
    name: "Questions",
    data: QuestionsBANcF0jaZZData,
    component: () => import("/vercel/path1/components/section/sipsBuilder/Questions.vue")
  },
  {
    name: "Reviews",
    data: Reviews4QWVSSHMnKData,
    component: () => import("/vercel/path1/components/section/sipsBuilder/Reviews.vue")
  },
  {
    name: "Schedule",
    data: ScheduleUW61cifKQfData,
    component: () => import("/vercel/path1/components/section/sipsBuilder/Schedule.vue")
  },
  {
    name: "SeasonalSipsClub",
    data: SeasonalSipsClubX4VFM2qTzyData,
    component: () => import("/vercel/path1/components/section/sipsBuilder/SeasonalSipsClub.vue")
  },
  {
    name: "SipsClubHero",
    data: SipsClubHerowsPfoMBUzcData,
    component: () => import("/vercel/path1/components/section/sipsBuilder/SipsClubHero.vue")
  },
  {
    name: "Swatches",
    data: SwatchesvNU8yxgbU5Data,
    component: () => import("/vercel/path1/components/section/sipsBuilder/Swatches.vue")
  },
  {
    name: "WhyChooseLifeboost",
    data: WhyChooseLifeboostEa4UnOg9dOData,
    component: () => import("/vercel/path1/components/section/sipsBuilder/WhyChooseLifeboost.vue")
  },
  {
    name: "AskedQuestions",
    data: AskedQuestionsb2TDMFLPVRData,
    component: () => import("/vercel/path1/components/section/snoozeTea/AskedQuestions.vue")
  },
  {
    name: "ButtonsInfo",
    data: ButtonsInfoONTTUfRfZuData,
    component: () => import("/vercel/path1/components/section/snoozeTea/ButtonsInfo.vue")
  },
  {
    name: "ContentButtons",
    data: ContentButtonsHV05QJKdjOData,
    component: () => import("/vercel/path1/components/section/snoozeTea/ContentButtons.vue")
  },
  {
    name: "ContentOpacity",
    data: ContentOpacitydik7OAENfeData,
    component: () => import("/vercel/path1/components/section/snoozeTea/ContentOpacity.vue")
  },
  {
    name: "CustomerReviews",
    data: CustomerReviewssXzDaDZOPWData,
    component: () => import("/vercel/path1/components/section/snoozeTea/CustomerReviews.vue")
  },
  {
    name: "CustomersVideo",
    data: CustomersVideoVN7ExeeVYUData,
    component: () => import("/vercel/path1/components/section/snoozeTea/CustomersVideo.vue")
  },
  {
    name: "Facts",
    data: FactsgGpHcdOmyBData,
    component: () => import("/vercel/path1/components/section/snoozeTea/Facts.vue")
  },
  {
    name: "Footer",
    data: Footerj7LKPVY7pQData,
    component: () => import("/vercel/path1/components/section/snoozeTea/Footer.vue")
  },
  {
    name: "Hero",
    data: HeroRUG4Gr4bQbData,
    component: () => import("/vercel/path1/components/section/snoozeTea/Hero.vue")
  },
  {
    name: "IconsText",
    data: IconsTextuAOSGALoiaData,
    component: () => import("/vercel/path1/components/section/snoozeTea/IconsText.vue")
  },
  {
    name: "Item",
    data: ItemWAZNKnY4m0Data,
    component: () => import("/vercel/path1/components/section/snoozeTea/Item.vue")
  },
  {
    name: "References",
    data: ReferencesdOajAakZeMData,
    component: () => import("/vercel/path1/components/section/snoozeTea/References.vue")
  },
  {
    name: "Swatches",
    data: SwatchesrIZzd7nIVLData,
    component: () => import("/vercel/path1/components/section/snoozeTea/Swatches.vue")
  },
  {
    name: "Content_section",
    data: Content_sectionPAZer1ZEuwData,
    component: () => import("/vercel/path1/components/section/strawberry/Content_section.vue")
  },
  {
    name: "Content_section_2",
    data: Content_section_2IhoxbibmV7Data,
    component: () => import("/vercel/path1/components/section/strawberry/Content_section_2.vue")
  },
  {
    name: "Content_section_3",
    data: Content_section_3oOi19SJMnFData,
    component: () => import("/vercel/path1/components/section/strawberry/Content_section_3.vue")
  },
  {
    name: "Content_section_4",
    data: Content_section_48gpho1TlLwData,
    component: () => import("/vercel/path1/components/section/strawberry/Content_section_4.vue")
  },
  {
    name: "Content_section_5",
    data: Content_section_5VcNfWj3VJcData,
    component: () => import("/vercel/path1/components/section/strawberry/Content_section_5.vue")
  },
  {
    name: "Content_section_6",
    data: Content_section_6X5WPt7XGYCData,
    component: () => import("/vercel/path1/components/section/strawberry/Content_section_6.vue")
  },
  {
    name: "Content_section_7",
    data: Content_section_7xA1l7yK266Data,
    component: () => import("/vercel/path1/components/section/strawberry/Content_section_7.vue")
  },
  {
    name: "Content_video",
    data: Content_videoEaACDaoBIWData,
    component: () => import("/vercel/path1/components/section/strawberry/Content_video.vue")
  },
  {
    name: "Footer",
    data: FooterkorbI5zSZkData,
    component: () => import("/vercel/path1/components/section/strawberry/Footer.vue")
  },
  {
    name: "Hero",
    data: HeroKOzR3x6JIGData,
    component: () => import("/vercel/path1/components/section/strawberry/Hero.vue")
  },
  {
    name: "sustainedEnergy",
    data: sustainedEnergyS4ZQlPmGxnData,
    component: () => import("/vercel/path1/components/section/sustainedEnergy.vue")
  },
  {
    name: "Subscription",
    data: SubscriptionEHSmm3WiPvData,
    component: () => import("/vercel/path1/components/section/swatches/Subscription.vue")
  },
  {
    name: "CardGrid",
    data: CardGridDgLnEsxiBkData,
    component: () => import("/vercel/path1/components/section/testimonials/CardGrid.vue")
  },
  {
    name: "ReviewGrid",
    data: ReviewGridEqXnb8zzJkData,
    component: () => import("/vercel/path1/components/section/testimonials/ReviewGrid.vue")
  },
  {
    name: "HeadingWhitText",
    data: HeadingWhitTextN5NmelfsXPData,
    component: () => import("/vercel/path1/components/section/textBlocks/HeadingWhitText.vue")
  },
  {
    name: "AlertsAnimations",
    data: AlertsAnimations4qJpF0J2Q3Data,
    component: () => import("/vercel/path1/components/section/tickers/AlertsAnimations.vue")
  },
  {
    name: "tissues",
    data: tissuesN5CYBItTNFData,
    component: () => import("/vercel/path1/components/section/tissues.vue")
  },
  {
    name: "Gifts",
    data: Giftsqpe7dcZMY3Data,
    component: () => import("/vercel/path1/components/section/upsell/Gifts.vue")
  },
  {
    name: "Header",
    data: HeaderkdYdXGFjsXData,
    component: () => import("/vercel/path1/components/section/upsell/Header.vue")
  },
  {
    name: "Hero",
    data: Herop4dBIRGUWcData,
    component: () => import("/vercel/path1/components/section/upsell/Hero.vue")
  },
  {
    name: "MakeEasy",
    data: MakeEasyUATHPmmX0cData,
    component: () => import("/vercel/path1/components/section/upsell/MakeEasy.vue")
  },
  {
    name: "Steps",
    data: Steps2YyzpHGc26Data,
    component: () => import("/vercel/path1/components/section/upsell/Steps.vue")
  },
  {
    name: "Sticking",
    data: Stickingo0qleVvyUTData,
    component: () => import("/vercel/path1/components/section/upsell/Sticking.vue")
  },
  {
    name: "VipProgram",
    data: VipProgramNixOvcrlhrData,
    component: () => import("/vercel/path1/components/section/upsell/VipProgram.vue")
  },
  {
    name: "footer",
    data: footerFUhg8sN8qqData,
    component: () => import("/vercel/path1/components/section/upsell/footer.vue")
  },
  {
    name: "BackgroundImageWithCheckList",
    data: BackgroundImageWithCheckListqJsB19YFMmData,
    component: () => import("/vercel/path1/components/section/zipifyBuilder/BackgroundImageWithCheckList.vue")
  },
  {
    name: "BgTexImage",
    data: BgTexImageqJHaSN10cEData,
    component: () => import("/vercel/path1/components/section/zipifyBuilder/BgTexImage.vue")
  },
  {
    name: "ContentWithLeftImage",
    data: ContentWithLeftImagej78doqz5zlData,
    component: () => import("/vercel/path1/components/section/zipifyBuilder/ContentWithLeftImage.vue")
  },
  {
    name: "DescriptionLeftRightImage",
    data: DescriptionLeftRightImage4J1OaS0jmZData,
    component: () => import("/vercel/path1/components/section/zipifyBuilder/DescriptionLeftRightImage.vue")
  },
  {
    name: "DescriptionListWithImage",
    data: DescriptionListWithImageUX6yGZQkjqData,
    component: () => import("/vercel/path1/components/section/zipifyBuilder/DescriptionListWithImage.vue")
  },
  {
    name: "Header",
    data: HeaderNdtKjFDpIWData,
    component: () => import("/vercel/path1/components/section/zipifyBuilder/Header.vue")
  },
  {
    name: "HeroBackgroundAndImage",
    data: HeroBackgroundAndImageJPpzJw5kriData,
    component: () => import("/vercel/path1/components/section/zipifyBuilder/HeroBackgroundAndImage.vue")
  },
  {
    name: "HeroWithBackground",
    data: HeroWithBackgroundbXGYwRGFQrData,
    component: () => import("/vercel/path1/components/section/zipifyBuilder/HeroWithBackground.vue")
  },
  {
    name: "ImageWhitQuote",
    data: ImageWhitQuoteLIFeoSjBAvData,
    component: () => import("/vercel/path1/components/section/zipifyBuilder/ImageWhitQuote.vue")
  },
  {
    name: "ImageWithIconsList",
    data: ImageWithIconsListhb4tRt6idGData,
    component: () => import("/vercel/path1/components/section/zipifyBuilder/ImageWithIconsList.vue")
  },
  {
    name: "LongTextWithImages",
    data: LongTextWithImages9PfixeJBkzData,
    component: () => import("/vercel/path1/components/section/zipifyBuilder/LongTextWithImages.vue")
  },
  {
    name: "ProductHighlightsAndRecognition",
    data: ProductHighlightsAndRecognitionoo436RlSHfData,
    component: () => import("/vercel/path1/components/section/zipifyBuilder/ProductHighlightsAndRecognition.vue")
  },
  {
    name: "Swatches",
    data: SwatchesMb2EJZ2p8rData,
    component: () => import("/vercel/path1/components/section/zipifyBuilder/Swatches.vue")
  },
  {
    name: "TestimonialsWithOptionsColors",
    data: TestimonialsWithOptionsColorsk3fDsm8M7CData,
    component: () => import("/vercel/path1/components/section/zipifyBuilder/TestimonialsWithOptionsColors.vue")
  },
  {
    name: "TitleWithImageCTA",
    data: TitleWithImageCTA5HyF8pPzVXData,
    component: () => import("/vercel/path1/components/section/zipifyBuilder/TitleWithImageCTA.vue")
  }
]