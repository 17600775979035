export default defineNuxtPlugin(() => {
    if (import.meta.client) {
        try {
            const AXON_EVENT_KEY = 'fe67e4d8-7a8b-475e-93a0-7c2fd688ac29';

            // Initialize ALBSS configuration immediately
            window.ALBSS = {
                event_key: AXON_EVENT_KEY
            };

            // Enhanced cookie handling
            const enhanceAxonTracking = {
                getCookie(name) {
                    try {
                        const value = `; ${document.cookie}`;
                        const parts = value.split(`; ${name}=`);
                        if (parts.length === 2) return parts.pop().split(';').shift();
                        return null;
                    } catch (e) {
                        console.error('Error getting cookie:', e);
                        return null;
                    }
                },
                setEnhancedCookie() {
                    try {
                        let axonId = this.getCookie('_axwrt');
                        if (!axonId) {
                            axonId = 'ax_' + Math.random().toString(36).substring(2) + '_' + Date.now();
                            document.cookie = `_axwrt=${axonId}; path=/; SameSite=Lax`;
                        }

                        const expirationDate = new Date();
                        expirationDate.setFullYear(expirationDate.getFullYear() + 1);
                        // Extract root domain lifeboostcoffee.com
                        const hostname = window.location.hostname;
                        const rootDomain = hostname.split('.').slice(-2).join('.');
                        document.cookie = `axwrt=${axonId}; expires=${expirationDate.toUTCString()}; domain=.${rootDomain}; path=/; SameSite=Lax`;
                    } catch (e) {
                        console.error('Error setting cookie:', e);
                    }
                }
            };

            // Set cookies immediately
            enhanceAxonTracking.setEnhancedCookie();

            // Initialize Axon immediately
            if (!window.axon) {
                window.axon = function() {
                    const a = window.axon;
                    a.performOperation ? 
                        a.performOperation.apply(a, arguments) : 
                        a.operationQueue.push(arguments);
                };
                window.axon.operationQueue = [];
                window.axon.ts = Date.now();
                window.axon.eventKey = AXON_EVENT_KEY;
            }

            // Load scripts immediately
            const scripts = [
                "https://s.axon.ai/pixel.js",
                "https://c.albss.com/p/l/loader.iife.js"
            ];

            const loadScript = (src) => {
                return new Promise((resolve, reject) => {
                    const script = document.createElement("script");
                    script.async = true;
                    script.src = src;
                    script.onload = resolve;
                    script.onerror = () => {
                        console.error(`Failed to load Axon script: ${src}`);
                        reject();
                    };
                    document.head.appendChild(script);
                });
            };

            // Load all scripts in parallel
            Promise.all(scripts.map(src => loadScript(src)))
                .then(() => {
                    // Initialize tracking after all scripts are loaded
                    if (window.axon) {
                        window.axon("init");
                        window.axon("track", "page_view");
                    }
                })
                .catch(error => {
                    console.error('Error loading Axon scripts:', error);
                });

            // Backup initialization
            const maxRetries = 5;
            let retryCount = 0;
            const retryInitialization = () => {
                if (window.axon && retryCount < maxRetries) {
                    try {
                        window.axon("init");
                        window.axon("track", "page_view");
                    } catch (e) {
                        console.error('Error during Axon initialization:', e);
                        retryCount++;
                        setTimeout(retryInitialization, 1000);
                    }
                }
            };
            setTimeout(retryInitialization, 2000);

        } catch (e) {
            console.error('Error in Axon plugin:', e);
        }
    }
});
